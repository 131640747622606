
// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {
	nav.section-header {
		.header-management {
			.management-search-engine {
				input {
					min-width: 340px;
				}
			}
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				& + .inner-service {
					margin-left: 70px;
				}
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	nav.section-header {
		.header-management {
			.management-logo {
				.logo-image {
					min-width: 150px;
					max-width: 150px;

					&.image-size-9 {
						min-width: 150px;
						max-width: 150px;
					}

					&.image-size-12 {
						min-width: 150px;
						max-width: 150px;
					}
				}
			}
		}
	}

	section.produkt-section-content {
		.content-inner {
			.product-info {
				.info-add {
					flex-wrap: wrap;

					.btn-fill {
						margin-top: 30px;
						margin-left: 0;
					}

					.btn-empty {
						margin-top: 30px;
					}

					.breakpoint {
						width: 100%;
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-inner {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.inner-contacts {
				width: 100%;
				text-align: center;

				p {
					padding-left: 0;
				}

				address {
					padding-left: 0;
				}

				.contacts-social-media {
					justify-content: center;
					margin-left: 0;
				}
			}

			.inner-company-data {
				margin-top: 40px;
				width: auto;
				padding: 50px 80px;
			}
		}

		.content-hours {
			padding: 0;
			margin-top: 60px;
		}

		.content-form {
			margin-top: 60px;
		}

		.content-map {
			margin-top: 60px;
			padding: 0;
		}

		.content-others {
			margin-top: 60px;
			padding: 0;
		}
	}

	section.section-footer {
		.footer-inner {
			flex-wrap: wrap;

			.inner-service {
				&:nth-child(1) {
					order: 1;
				}

				&:nth-child(2) {
					order: 2;
				}
				
				&:nth-child(3) {
					order: 3;
				}
				
				&:nth-child(4) {
					order: 4;
				}
				
				&:nth-child(5) {
					order: 6;
					margin-left: 0;
					margin-top: 40px;
				}
			}

			.breakpoint {
				order: 5;
				width: 100%;
			}
		}

		.footer-credits {
			.credits-copyright {
				.copyright-logo {
					.logo-image {
						min-width: 150px;
						max-width: 150px;

						&.image-size-9 {
							min-width: 150px;
							max-width: 150px;
						}

						&.image-size-12 {
							min-width: 150px;
							max-width: 150px;
						}
					}
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.products-flexbox {
		.product-service {
			width: 31%;

			.badge-wrapper {
				.badge {
					width: 80px;
					height: 20px;
					font-size: 9px;
				}
			}
		}

		&.full-width {
			.product-service {
				width: 23.5%;
			}
		}

		&.grid-list {
			.product-service {
				header {
					min-width: 27%;
				}
			}
		}
	}

	.category-nav-wrapper {
		position: relative;

		.category-nav {
			li {
				a {
					font-size: 15px;
					letter-spacing: 0px;
				}

				&:not(:last-child) {
					padding-bottom: 15px;
				}

				ul {
					margin-top: 12px;
					padding-left: 17px;

					li {
						padding-bottom: 0 !important;

						a {
							font-size: 13px;
							letter-spacing: 0px;
						}

						ul {
							margin-top: 10px;
						}
					}
				}
			}
		}

		.filter-nav {
			.checkbox-wrapper {
				.radio-box {
					font-size: 15px;
					letter-spacing: 0px;

					.box-square {
						margin-top: 4px;
					}
				}
			}

			.range-wrapper {
				input[type=number] {
					margin: 0 3px;
					font-size: 13px;
				}

				p {
					font-size: 13px;
				}
			}

			.buttons-wrapper {
				.btn-clear {
					font-size: 11px;
				}

				.btn-filter {
					font-size: 11px;
					padding: 3px 7px;
				}
			}
		}
	}

	nav.section-header {
		.header-top {
			p {
				display: none;
			}

			.top-service {
				justify-content: space-between;
				width: 100%;
			}

			.top-social-media {
				&:after {
					content: none;
				}
			}
		}

		.header-management {
			flex-wrap: wrap;
			padding: 20px 0;

			.management-logo {
				order: 1;
			}

			.management-offer {
				order: 4;
			}

			.management-breakpoint {
				height: 20px;
				order: 3;
				width: 100%;
				display: block;
			}

			.management-search-engine {
				order: 5;
			}

			.management-service {
				order: 2;
			}
		}
	}

	section.section-welcome {
		.welcome-item {
			display: none;
		}

		.owl-carousel-welcome {
			display: none;
		}

		.welcome-mobile-item {
			display: block;
		}

		.owl-carousel-welcome-mobile {
			display: block;
		}
	}

	section.section-categories {
		.categories-flexbox {
			.flexbox-service {
				.service-inner {
					.inner-name {
						padding: 0;

						.name-box {
							padding: 10px;
							font-size: 14px;
							transition: all 0.4s;
						}
					}

					&:hover {
						.inner-name {
							bottom: 0;

							.name-box {
								padding: 20px 10px;
							}
						}
					}
				}
			}
		}
	}

	section.produkt-section-content {
		.content-inner {
			.product-info {
				.info-producer {
					> * {
						&:not(:first-child) {
							margin-top: 7px;
						}
					}

					.producer-inner {
						display: block;

						p {
							font-size: 12px;

							&:nth-child(2) {
								margin-left: 0;
							}
						}
					}
				}

				.info-features {
					margin-top: 30px;

					> * {
						&:not(:first-child) {
							margin-top: 7px;
						}
					}

					.feature-inner {
						display: block;

						p {
							font-size: 12px;

							&:nth-child(2) {
								margin-left: 0;
							}
						}
					}

					.select-wrapper-flexbox {
						display: block;

						label {
							font-size: 12px;
							margin-right: 0;
						}
					}
				}

				.info-price {
					margin-top: 30px;

					.price-availability {
						.availability-inner {
							p {
								font-size: 12px;
							}
						}

						.availability-comment {
							p {
								font-size: 12px;
							}
						}
					}
				}

				.info-add {
					.service-quantity {
						.input-number {
							width: 50px;
						}
					}

					.btn-empty {
						margin-left: 0;
					}
				}

				.info-codes {
					margin-top: 30px;
					padding-top: 30px;
					display: block;

					.code-inner {
						display: block;
						margin-top: 0;

						p {
							font-size: 12px;

							&:nth-child(2) {
								margin-left: 0;
							}
						}
					}

					> * {
						&:not(:first-child) {
							margin-top: 7px;
						}
					}
				}

				.info-description {
					margin-top: 30px;
					padding-top: 30px;

					.description-heading {
						font-size: 12px;
					}

					p, li {
						font-size: 12px;
					}
				}

				.info-download {
					padding-top: 30px;
					margin-top: 30px;

					.download-heading {
						font-size: 12px;
						margin-bottom: 20px;
					}

					ul {
						li {
							a {
								span {
									font-size: 13px;
								}
							}
						}
					}
				}
			}
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				&:nth-child(1) {
					order: 1;
				}

				&:nth-child(2) {
					margin-left: 0;
					order: 4;
				}
				
				&:nth-child(3) {
					order: 5;
				}
				
				&:nth-child(4) {
					order: 6;
				}
				
				&:nth-child(5) {
					order: 2;
					margin-left: 70px;
					margin-top: 0;
				}
			}

			.breakpoint {
				order: 3;
				height: 40px;
			}
		}

		.footer-credits {
			.credits-payment {
				display: none;
			}
		}
	}
}

@media (max-width: 785.98px) {
	.modal-service-wrapper {
		.modal-service {
			.service-inner {
				.service-button {
					top: 15px;
					right: 15px;
					padding: 20px 15px 23px 15px;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.body-wrapper {
		&.active {
			transform: translateX(-100%);
		}
	}

	.breadcrumb-wrapper {
		&.subpage-hidden {
			display: none;
		}
	}

	.products-flexbox {
		.product-service {
			width: 48.25%;
		}

		&.full-width {
			.product-service {
				width: 48.25%;
			}
		}
	}

	.categories-flexbox {
		.category-service {
			width: 48.25%;
		}
	}

	.news-flexbox {
		.news-service {
			width: 48.25%;
		}
	}

	.header-bag-sidebar {
		width: 100%;

		.sidebar-service {
			width: 100%;

			.service-heading {
				padding: 20px;
				min-height: 76px;
			}

			.service-inner {
				padding: 20px;
				width: 100%;
				max-height: calc(~'100vh - 205px');

				.product-service-wrapper {
					margin-top: 20px;

					.service-image-wrapper {
						min-width: 80px;
						max-width: 80px;
						padding-top: 80px * 1.6;

						&.square {
							padding-top: 80px;
						}
					}

					.service-name {
						.info {
							margin-top: 5px;
						}
					}
				}
			}

			.service-summary {
				bottom: 61px;
				padding: 10px 21px 10px 20px;
			}

			.service-footer {
				padding: 0 20px 20px 20px;
			}

			.product-modal {
				.modal-inner {
					padding: 20px;

					.inner-heading {
						.heading {
							font-size: 18px;
							line-height: 22px;
						}

						.btn-modal-close {
							height: 22px;
							min-width: 22px;
							max-width: 22px;

							span {
								width: 22px;
							}
						}
					}

					.service-image-wrapper-outer {
						margin-top: 30px;
					}

					.inner-features {
						margin-top: 30px;

						.feature-inner {
							p {
								font-size: 11px;
								letter-spacing: 0;

								&:nth-child(2) {
									margin-left: 10px;
								}
							}
						}
					}

					.inner-description {
						margin-top: 25px;
					}

					.inner-footer {
						padding-top: 30px;
					}
				}
			}
		}

		&.favorite {
			.sidebar-service {
				.service-inner {
					max-height: calc(~'100vh - 157px');
				}

				.service-footer {
					padding-top: 20px;
				}
			}
		}
	}

	nav.section-header {
		.header-top {
			display: none;
		}

		.header-management {
			.management-offer {
				display: none;
			}

			.management-search-engine {
				width: 100%;

				input {
					width: 100%;
					min-width: auto;
				}
			}

			.management-service {
				.service-icons {
					a, button {
						svg {
							height: 21px;
							width: 21px;
						}

						.cost-line {
							display: none;
						}
					}

					& + .service-icons {
						margin-left: 30px;
					}
				}

				.service-nav-button {
					margin-left: 20px;
					display: block;
				}
			}
		}
	}

	section.section-welcome {
		& + section.section-categories {
			margin-top: 40px;
		}
	}

	section.section-categories {
		.categories-flexbox {
			flex-wrap: wrap;

			.flexbox-service {
				margin-top: 20px;

				&.service-wrapper {
					.service-inner {
						padding-top: 70% !important;

						& + .service-inner {
							margin-top: 20px;
						}
					}
				}
			}

			&.version-1 {
				.flexbox-service {
					width: 100%;
					padding-top: 70%;
				}
			}

			&.version-2 {
				.flexbox-service {
					width: 100%;
					padding-top: 70%;
				}
			}

			&.version-3 {
				.flexbox-service {
					width: 100%;
					padding-top: 70%;

					&.service-double {
						width: 100%;
						flex-direction: row;
						align-items: flex-start;
						justify-content: space-between;
						align-content: space-between;
						flex-wrap: wrap;

						.service-inner {
							width: 100%;
							padding-top: 70%;
						}
					}
				}
			}
		}
	}

	section.section-advantages {
		margin-top: 40px;

		.advantages-flexbox {
			flex-wrap: wrap;
			justify-content: center;

			.flexbox-service {
				margin-top: 30px;
				width: 100% / 3;
			}
		}
	}

	section.section-offer {
		margin-top: 70px;

		.section-heading {
			h2 {
				font-size: 28px;
			}
		}
	}

	section.section-industries {
		margin-top: 70px;

		.section-heading {
			h2 {
				font-size: 28px;
			}
		}

		.industries-flexbox {
			.flexbox-service {
				margin: 5px;
				padding: 5px 13px;
				font-size: 14px;
			}
		}
	}

	section.section-cta {
		margin-top: 70px;

		.cta-item {
			.item-description {
				padding: 50px 30px;

				.text {
					font-size: 30px;
					line-height: 35px;
				}
			}
		}
	}

	section.section-blog {
		margin-top: 70px;

		&.subpage {
			padding: 70px 0;

			.news-flexbox {
				.news-service {
					&:nth-child(3) {
						display: flex;
					}
				}
			}
		}

		.section-heading {
			text-align: center;

			h1, h2 {
				font-size: 28px;
			}
		}

		.news-flexbox {
			margin-top: 15px;

			.news-service {
				&:nth-child(3) {
					display: none;
				}
			}
		}

		.btn-more-products-wrapper {
			margin-top: 50px;
		}
	}

	section.section-newsletter {
		margin-top: 70px;
		padding: 70px 0;

		.section-heading {
			h2 {
				font-size: 28px;
			}
		}
	}

	section.kategoria-section-content {
		padding-bottom: 70px;
		padding-top: 0;

		.content-flexbox {
			.flexbox-sidebar {
				display: none;
			}

			.flexbox-body {
				width: 100%;

				.heading {
					display: block;
				}

				.body-heading {
					.heading-categories-button {
						display: flex;
					}

					.heading-text {
						display: none;
					}

					.inner-wrapper {
						.heading-grid-wrapper {
							display: none;
						}
					}
				}

				.btn-more-products-wrapper {
					margin-top: 50px;
				}
			}
		}

		.content-description {
			padding-top: 50px;
			margin-top: 60px;

			.description-inner {
				h1, h2 {
					font-size: 28px;
				}
			}
		}
	}

	section.produkt-section-content {
		padding-bottom: 70px;
		padding-top: 0;

		.heading {
			display: block;
		}

		.content-inner {
			flex-direction: column;

			.product-gallery {
				width: 100%;
			}

			.product-info {
				margin-top: 40px;
				width: 100%;

				.info-add {
					.btn-fill {
						margin-right: 30px;
					}
				}
			}
		}
	}

	section.static-page-section-content {
		padding: 70px 0;

		&.no-padding-mobile {
			padding: 0 0 70px 0;
		}

		.content-inner {
			.backlink {
				display: inline-block;
				width: 100%;
				margin-bottom: 30px;

				.backlink-inner {
					padding: 12px 0;
					border-bottom: 0.5px solid #ccc;

					span {
						font-size: 17px;
						font-weight: 500;
						letter-spacing: 0;
						text-transform: none;
						color: #666;
					}

					img {
						margin-right: 10px;
						transform: rotate(180deg);
						height: 10px;
					}
				}
			}

			.heading {
				font-size: 28px;
				letter-spacing: 0.5px;
			}
		}
	}

	section.faq-section-content {
		padding: 70px 0;

		.content-inner {
			.heading {
				h1 {			
					font-size: 32px;
				}
			}

			.accordion {
				margin-top: 40px;

				.accordion-item {
					.accordion-header {
						button {
							font-size: 15px;
							font-weight: 500;
						}
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		padding: 70px 0;

		.content-inner {
			.inner-contacts {
				h1 {
					font-size: 28px;
				}

				p {
					font-size: 22px;
				}

				address {
					.address-service {
						.service-inner {
							svg {
								height: 18px;
								width: 18px;
								margin-right: 8px;
							}

							span {
								font-size: 18px;
								letter-spacing: -0.3px;
							}
						}
					}
				}

				.contacts-social-media {
					li {
						&.heading {
							.small-letters(#000);
							opacity: 0.7;
						}
					}
				}
			}

			.inner-company-data {
				padding: 40px;
			}
		}

		.content-hours {
			.heading {
				font-size: 28px;
			}

			.hours-inner {
				.inner-service {
					margin: 3px;
					padding: 5px;
				}
			}
		}

		.content-map-form-wrapper {
			flex-direction: column;

			.content-form {
				width: 100%;
			}

			.content-map {
				width: 100%;

				.map-inner {
					height: auto;
					padding-top: 50%;
				}
			}
		}

		.content-form {
			.heading {
				font-size: 28px;
				text-align: center;
			}

			.buttons-wrapper {
				> * {
					width: 100%;
					text-align: center;
				}
			}
		}

		.content-others {
			.heading {
				font-size: 28px;
			}

			.others-inner {
				.inner-service {
					margin: 1%;
					padding: 20px 10px;
					width: 48%;
				}
			}
		}
	}

	section.section-footer {
		margin-top: 70px;

		&.active {
			transform: translateX(-100%);
		}

		.footer-credits {
			margin-top: 50px;

			.credits-copyright {
				width: 100%;
				flex-direction: column;
				text-align: center;

				.divider {
					display: none;
				}

				p {
					margin-top: 15px;
					font-size: 12px;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	body {
		.btn-border {
			padding: 10px 18px;
			.small-letters(#000);
		}

		.btn-empty {
			.small-letters(#000);
		}

		.btn-fill {
			padding: 10px 18px;
			.small-letters(#000);

			&.btn-small {
				padding: 7px 14px;
			}
		}
	}

	.small-letters(@color) {
		font-size: 10.5px;
		letter-spacing: 0.4px;
	}

	.toast-container {
		right: 0;
		bottom: 0;
		padding: 0;
		width: 100%;

		.toast {
			margin-left: 15px;
			margin-bottom: 15px;
			width: calc(~'100% - 30px');
		}
	}

	.select-wrapper {
		select {
			.small-letters(#000);
			padding-right: 30px;
		}
	}

	.header-bag-sidebar {
		.sidebar-service {
			.service-inner {
				max-height: calc(~'100vh - 225px');

				.product-service-wrapper {
					.product-service {
						flex-wrap: wrap;
						width: calc(~'100% - 25px');
					}

					.service-name {
						width: calc(~'100% - 80px');
					}

					.service-quantity {
						margin-left: 0;

						.input-number {
							width: 30px;
						}

						.input-number,
						.input-number-decrement,
						.input-number-increment {
							height: 25px;
						}

						.input-number-decrement,
						.input-number-increment {
							min-width: 25px;
						}
					}

					.breakpoint {
						height: 10px;
						display: block;
						width: 100%;
					}
				}
			}

			.service-menu {
				.category-nav-wrapper {

					.inner-footer {
						flex-direction: column;
						width: 100%;

						> * {
							width: 100%;
							text-align: center;

							& + * {
								margin-top: 10px;
								margin-left: 0;
							}
						}
					}
				}
			}

			.service-summary {
				bottom: 105px;
				font-size: 14px;
				text-align: center;

				br {
					content: '';

					&:after {
						content: ' '; 
					}
				}
			}

			.service-footer {
				flex-direction: column-reverse;
				width: 100%;

				> * {
					width: 100%;
					text-align: center;

					& + * {
						margin-bottom: 10px;
						margin-left: 0;
					}
				}
			}

			.product-modal {
				.modal-inner {
					.inner-footer {
						flex-direction: column-reverse;
						width: 100%;

						> * {
							width: 100%;
							text-align: center;

							& + * {
								margin-bottom: 10px;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}

	nav.section-header {
		.header-management {
			.management-logo {
				.logo-text {
					font-size: 20px;
					letter-spacing: -0.7px;

					span {
						font-size: 25px;
					}
				}
			}
		}
	}

	section.section-advantages {
		.advantages-flexbox {
			.flexbox-service {
				width: 100% / 2;
			}
		}
	}

	section.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				.body-heading {
					.heading-categories-button {
						span {
							.small-letters(#fff);
						}	
					}
				}

				.btn-more-products-wrapper {
					margin-top: 50px;

					.btn-more-products {
						padding: 10px 18px;
						.small-letters(#000);
					}
				}
			}
		}
	}

	section.static-page-section-content {
		.content-inner {
			.backlink {
				margin-bottom: 20px;
			}

			.inner-info {
				margin-top: 25px;

				.info-service {
					.small-letters(#000);
					margin-right: 20px;
				}
			}

			.inner-short-description {
				margin-top: 25px;
				padding-top: 25px;
			}

			.inner-main-image {
				margin-top: 25px;
			}

			.inner-description {
				margin-top: 25px;

				h1 {
					font-size: 28px;
				}

				h2 {
					font-size: 24px;
				}

				h3 {
					font-size: 20px;
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-others {
			.others-inner {
				.inner-service {
					margin: 1%;
					width: 100%;
				}
			}
		}
	}

	section.section-footer {
		.footer-inner {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			.inner-service {
				.social-media {
					justify-content: center;
				}

				& + .inner-service {
					margin-left: 0;
					margin-top: 42px;
				}
			}

			.breakpoint {
				display: none;
			}
		}

		.footer-credits {
			.credits-copyright {
				.copyright-logo {
					.logo-text {
						font-size: 20px;
						letter-spacing: -0.7px;

						span {
							font-size: 25px;
						}
					}
				}
			}
		}
	}

	.cookies-alert {
		bottom: 15px;
		left: 15px;
		width: calc(~'100% - 30px');
		padding: 20px;
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

}