@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNig.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNig.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Work Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f9f9f9;
  position: relative;
}
body.no-js .intro {
  opacity: 1;
  transform: none;
}
body .intro {
  opacity: 0;
  transform: translateY(-20px);
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn.btn-default {
  padding: 0;
  border-radius: 0;
}
body .btn .btn-inner {
  display: flex;
  align-items: center;
}
body .btn .inner-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
body .btn .inner-text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: inherit;
}
body .btn.pulse-animate {
  position: relative;
}
body .btn.pulse-animate:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0.8);
  display: block;
  width: 20px;
  height: 20px;
  background-color: #c31432;
  filter: blur(2px);
  border-radius: 15px;
  animation: pulse-dot 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse-dot {
  40% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
    transform: scale(1);
  }
  60% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
body .btn-border {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 2px;
  display: inline-block;
  outline: none;
  background: none;
}
body .btn-empty {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  padding: 0;
  border: none;
  display: inline-block;
  outline: none;
  background: none;
  transition: all 0.4s;
}
body .btn-empty.btn-icon {
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
body .btn-empty.btn-icon svg {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  fill: #000;
  margin-right: 10px;
}
body .btn-empty.btn-icon span {
  white-space: nowrap;
}
body .btn-empty:hover {
  text-decoration: underline;
}
body .btn-fill {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 2px;
  background: #000;
  display: inline-block;
  outline: none;
}
body .btn-fill.btn-icon {
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
body .btn-fill.btn-icon svg {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  fill: #fff;
  margin-right: 10px;
}
body .btn-fill.btn-icon span {
  white-space: nowrap;
}
body .btn-border.btn-small,
body .btn-empty.btn-small,
body .btn-fill.btn-small {
  padding: 5px 20px;
}
body .btn-border.loading,
body .btn-empty.loading,
body .btn-fill.loading {
  position: relative;
  pointer-events: none;
}
body .btn-border.loading:before,
body .btn-empty.loading:before,
body .btn-fill.loading:before {
  content: '';
  position: absolute;
  top: -10%;
  left: -5%;
  width: 110%;
  height: 120%;
  background: #fff;
  opacity: 0.85;
  filter: blur(1px);
}
body .btn-border.loading:after,
body .btn-empty.loading:after,
body .btn-fill.loading:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../img/icons/loading.svg) center;
  background-size: cover;
  height: 18px;
  width: 18px;
  animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
}
@keyframes loader-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none;
}
.return-to-top {
  position: fixed;
  z-index: 333;
  bottom: 15px;
  right: 15px;
  transition: all 0.3s;
  border-radius: 3px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
}
.return-to-top img,
.return-to-top svg {
  height: 35%;
  width: 35%;
}
.return-to-top svg {
  transform: rotate(-90deg);
  fill: #fff;
}
.return-to-top.active {
  opacity: 1;
  visibility: visible;
}
.demo-label {
  position: fixed;
  z-index: 1001;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  transition: all 0.4s;
  border-radius: 2px;
  text-align: center;
  opacity: 0.5;
}
.demo-label .label-heading {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
}
.demo-label .btn-fill {
  margin-top: 10px;
  border-radius: 30px;
  font-size: 12px;
}
.demo-label:hover {
  opacity: 1;
}
.toast-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;
}
.toast-container .toast {
  z-index: 999;
  position: relative;
  left: 0;
  bottom: 0;
  border: none;
}
.toast-container .toast .toast-header {
  border-radius: 2px 2px 0 0;
}
.toast-container .toast .toast-header.success {
  background: olivedrab;
}
.toast-container .toast .toast-header.warning {
  background: crimson;
}
.toast-container .toast .toast-body {
  background: #fff;
  border-radius: 0 0 2px 2px;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  z-index: 2222;
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 50px;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 30px;
  right: 15px;
  border: 0.5px solid #ccc;
  border-radius: 3px;
  background: none;
  z-index: 111;
  background: #fff;
  padding: 25px 20px 28px 20px;
  outline: none;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-inner {
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-inner .button-bar {
  display: block;
  width: 22px;
  transition: all 0.2s;
  height: 2px;
  background: #000;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-inner .button-bar:nth-child(1) {
  transform: rotate(45deg) translateY(2.5px) translateX(2.5px);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-inner .button-bar:nth-child(2) {
  opacity: 0;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-inner .button-bar:nth-child(3) {
  transform: rotate(-45deg);
}
.modal-service-wrapper.full-width .modal-service {
  padding: 0;
}
.modal-service-wrapper.full-width .modal-service .service-inner {
  padding: 0;
}
.modal-service-wrapper.full-width .modal-service .service-inner .service-map {
  position: relative;
  min-height: 100vh;
}
.modal-service-wrapper.full-width .modal-service .service-inner .service-map .easypack-widget {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.modal-service-wrapper.full-width .modal-service .service-inner .service-map .easypack-widget .easypack-search-widget .search-group {
  padding-right: 100px;
}
.modal-service-wrapper.full-width .modal-service .service-inner .service-map .easypack-widget .map-list-row {
  height: 100%;
  min-height: 400px;
}
.alert-wrapper {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2222;
}
.alert-wrapper .alert {
  max-width: 90%;
  margin-bottom: 0;
  margin-left: 5%;
  box-shadow: 5px 10px 35px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  text-align: center;
  font-size: 14px;
}
.alert-wrapper .alert button {
  height: 15px;
  width: 15px;
  padding: 10px;
  opacity: 1;
  outline: none;
  background: url(../img/icons/close.svg) center;
  background-repeat: no-repeat;
  background-size: 40%;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  box-shadow: none;
}
.breadcrumb-wrapper {
  padding: 0 0 30px 0;
  margin: 0;
  transition: all 0.4s;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  padding: 0;
  background: none;
  margin-bottom: 0;
  display: flex;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  transition: all 0.4s;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-weight: 200;
}
.input-service {
  margin-top: 15px;
}
.input-service label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  text-transform: none;
  padding-left: 15px;
  color: #000;
  text-align: left;
  width: 100%;
}
.input-service input,
.input-service textarea {
  width: 100%;
  display: block;
  border: none;
  height: 45px;
  border-radius: 50px;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid #000;
  transition: all 0.4s;
}
.input-service input:focus,
.input-service textarea:focus {
  outline: none;
  background: #f1fbf4;
  box-shadow: none;
}
.input-service input.warning,
.input-service textarea.warning {
  box-shadow: none;
}
.input-service .input-alert {
  padding-left: 15px;
  margin-top: 7px;
  font-size: 11px;
  color: #dc3545;
}
.input-service .value {
  color: #000;
  font-size: 15px;
  text-align: left;
}
.input-service .textarea-value {
  padding: 15px;
  background: #f9f9f9;
  border-radius: 2px;
  color: #000;
  font-size: 14px;
  font-weight: 300;
}
.input-service textarea {
  height: 150px;
  border-radius: 5px;
}
.input-service.loading {
  position: relative;
  pointer-events: none;
}
.input-service.loading:before {
  content: '';
  position: absolute;
  top: -2.5%;
  left: -2.5%;
  width: 105%;
  height: 105%;
  background: #fff;
  opacity: 0.85;
  filter: blur(1px);
}
.input-service.loading:after {
  content: '';
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../img/icons/loading.svg) center;
  background-size: cover;
  height: 18px;
  width: 18px;
  animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
}
.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin: 0;
}
.checkbox-wrapper .radio-box {
  display: flex;
  font-size: 12px;
  color: #000;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box a {
  text-decoration: underline;
}
.checkbox-wrapper .radio-box .box-square {
  content: '';
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  display: block;
  border: 2px solid #999;
  margin-top: 3px;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper.checkbox-alert .radio-box {
  color: #dc3545;
}
.checkbox-wrapper.checkbox-alert .radio-box .box-square {
  border-color: #dc3545;
}
.select-wrapper {
  position: relative;
  border: 1px solid #999;
  border-radius: 30px;
  overflow: hidden;
}
.select-wrapper select {
  cursor: pointer;
  padding: 6px 100px 5px 15px;
  border: none;
  border-radius: 50px;
  background: #fff;
  height: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  font-size: 11px;
  width: 100%;
}
.select-wrapper svg {
  position: absolute;
  fill: #000;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.products-flexbox .product-service {
  margin-top: 3.5%;
  width: 23.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
}
.products-flexbox .product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 7px;
  left: 7px;
  width: 100%;
}
.products-flexbox .product-service .badge-wrapper .badge {
  width: 90px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #fff;
  border-radius: 2px;
  margin-bottom: 6px;
}
.products-flexbox .product-service .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.sale {
  background: crimson;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.own {
  background: #ef7b0a;
  display: none;
}
.products-flexbox .product-service .service-favorite {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 222;
  display: flex;
  padding: 5px;
  border: none;
  background: #fff;
  border-radius: 2px;
  outline: none;
}
.products-flexbox .product-service .service-favorite svg {
  height: 15px;
  width: 15px;
  transition: all 0.4s;
}
.products-flexbox .product-service .service-favorite svg path {
  transition: all 0.4s;
}
.products-flexbox .product-service .service-favorite svg path:nth-child(1) {
  fill: #fff;
}
.products-flexbox .product-service .service-favorite.checked svg path:nth-child(1),
.products-flexbox .product-service .service-favorite:hover svg path:nth-child(1) {
  fill: #e9b9b9;
}
.products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 160%;
  display: block;
  transition: all 0.4s;
  position: relative;
  background: #f3f3f3;
}
.products-flexbox .product-service .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 121;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
.products-flexbox .product-service .service-image-wrapper .image-cover .cover-inner {
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox .product-service .service-image-wrapper .image-cover .cover-inner img {
  height: 16px;
  width: 16px;
}
.products-flexbox .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.products-flexbox .product-service .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
}
.products-flexbox .product-service .service-image-wrapper.square {
  padding-top: 100%;
}
.products-flexbox .product-service .service-image-wrapper.cover .service-image img {
  object-fit: cover;
}
.products-flexbox .product-service .info {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  font-size: 10px;
  text-align: center;
  display: block;
}
.products-flexbox .product-service .info.list-view {
  display: none;
}
.products-flexbox .product-service .name {
  text-align: center;
  margin-top: 15px;
  color: #000;
  font-weight: 500;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox .product-service .name.list-view {
  display: none;
}
.products-flexbox .product-service .price {
  text-align: center;
  margin-top: 10px;
  color: #000;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
.products-flexbox .product-service .price small {
  font-size: 18px;
  font-weight: 400;
  color: #999;
  margin-right: 7px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price small:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 0.5px;
  background: #666;
}
.products-flexbox .product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.products-flexbox .product-service.promotion .promotion {
  display: flex !important;
}
.products-flexbox .product-service.promotion .price {
  color: tomato;
}
.products-flexbox .product-service.promotion .price small {
  display: inline-block;
}
.products-flexbox .product-service.new .new {
  display: flex !important;
}
.products-flexbox .product-service.bestseller .bestseller {
  display: flex !important;
}
.products-flexbox .product-service.sale .sale {
  display: flex !important;
}
.products-flexbox .product-service.own .own {
  display: flex !important;
}
.products-flexbox .product-service:hover .service-image-wrapper .image-cover {
  opacity: 1;
}
.products-flexbox .product-service:hover .name {
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-color: #000 !important;
  text-decoration-thickness: 0.5px !important;
}
.products-flexbox .product-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.loading {
  position: relative;
  pointer-events: none;
}
.products-flexbox.loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  opacity: 0.85;
  filter: blur(1px);
}
.products-flexbox.loading:after {
  content: '';
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  background: url(../img/icons/loading.svg) center;
  background-size: cover;
  height: 40px;
  width: 40px;
  animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
}
.products-flexbox.grid-list .product-service {
  width: 100% !important;
  flex-direction: row;
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service header {
  min-width: 17%;
  margin-right: 15px;
}
.products-flexbox.grid-list .product-service .info {
  margin-top: 0;
  text-align: left;
}
.products-flexbox.grid-list .product-service .info.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .info.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 0;
  text-align: left;
}
.products-flexbox.grid-list .product-service .name.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .name.list-view {
  padding-right: 50px;
  display: block;
}
.products-flexbox.grid-list .product-service .price {
  text-align: left;
}
.products-flexbox.grid-list .product-service:not(:first-child) {
  margin-top: 30px;
}
.categories-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.categories-flexbox .category-service {
  margin-top: 3.5%;
  width: 31%;
  transition: all 0.4s;
}
.categories-flexbox .category-service .service-image-wrapper {
  width: 100%;
  padding-top: 160%;
  display: block;
  transition: all 0.4s;
  position: relative;
  background: #f3f3f3;
}
.categories-flexbox .category-service .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 121;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
.categories-flexbox .category-service .service-image-wrapper .image-cover .cover-inner {
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.categories-flexbox .category-service .service-image-wrapper .image-cover .cover-inner img {
  height: 16px;
  width: 16px;
}
.categories-flexbox .category-service .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.categories-flexbox .category-service .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
}
.categories-flexbox .category-service .service-image-wrapper.square {
  padding-top: 100%;
}
.categories-flexbox .category-service .service-image-wrapper.cover .service-image img {
  object-fit: cover;
}
.categories-flexbox .category-service .name {
  text-align: center;
  margin-top: 15px;
  color: #000;
  font-weight: 500;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.categories-flexbox .category-service:hover .service-image-wrapper .image-cover {
  opacity: 1;
}
.categories-flexbox .category-service:hover .name {
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-color: #000 !important;
  text-decoration-thickness: 0.5px !important;
}
.categories-flexbox .category-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.categories-flexbox.loading {
  position: relative;
  pointer-events: none;
}
.categories-flexbox.loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  opacity: 0.85;
  filter: blur(1px);
}
.categories-flexbox.loading:after {
  content: '';
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  background: url(../img/icons/loading.svg) center;
  background-size: cover;
  height: 40px;
  width: 40px;
  animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
}
.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.news-flexbox .news-service {
  margin-top: 3.5%;
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
}
.news-flexbox .news-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  display: block;
  transition: all 0.4s;
  position: relative;
  background: #f3f3f3;
}
.news-flexbox .news-service .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 121;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
.news-flexbox .news-service .service-image-wrapper .image-cover .cover-inner {
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-flexbox .news-service .service-image-wrapper .image-cover .cover-inner img {
  height: 16px;
  width: 16px;
}
.news-flexbox .news-service .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.news-flexbox .news-service .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
}
.news-flexbox .news-service .date {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  margin-top: 15px;
  font-size: 11px;
  display: block;
}
.news-flexbox .news-service .date.list-view {
  display: none;
}
.news-flexbox .news-service .name {
  margin-top: 3px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  padding-right: 20px;
  display: block;
  transition: all 0.4s;
}
.news-flexbox .news-service .name.list-view {
  display: none;
}
.news-flexbox .news-service:hover .service-image-wrapper .image-cover {
  opacity: 1;
}
.news-flexbox .news-service:hover .name {
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-color: #000 !important;
  text-decoration-thickness: 0.5px !important;
}
.news-flexbox .news-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.body-wrapper {
  transition: all 0.4s;
}
.body-wrapper.active {
  transform: translateX(-620px);
  filter: blur(5px);
}
.header-live {
  transition: all 0.4s;
}
.header-live .alert {
  padding: 0;
  border-radius: 0;
  border: none;
  margin: 0;
  background: #000;
  text-align: center;
}
.header-live .alert .alert-inner {
  font-size: 9.2px;
  padding: 7px 25px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
}
.header-live .alert .alert-inner .btn-close {
  outline: none;
  box-shadow: none;
  height: 12px;
  width: 12px;
  transition: opacity 0.4s;
  border: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: none;
  padding: 0;
  cursor: pointer;
}
.header-live .alert .alert-inner .btn-close span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 12px;
  height: 1px;
  background: #fff;
}
.header-live .alert .alert-inner .btn-close span:nth-child(1) {
  transform: rotate(45deg) translateY(-50%);
}
.header-live .alert .alert-inner .btn-close span:nth-child(2) {
  transform: rotate(-45deg) translateY(-50%);
}
.header-live .alert .alert-inner .btn-close:after {
  content: none;
}
.header-bag-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service {
  position: absolute;
  height: 100%;
  background: #fff;
  right: 0;
  top: 0;
  box-shadow: -10px 5px 35px 0px rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service .service-heading {
  padding: 40px;
  min-height: 116px;
  display: flex;
  justify-content: space-between;
  color: #000;
}
.header-bag-sidebar .sidebar-service .service-heading .heading {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}
.header-bag-sidebar .sidebar-service .service-heading .delivery {
  text-align: right;
  font-size: 13px;
  line-height: 18px;
}
.header-bag-sidebar .sidebar-service .service-inner {
  padding: 0 40px 40px 40px;
  width: 620px;
  max-height: calc(100vh - 285px);
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(245, 245, 245, 0.8);
  z-index: 222;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image {
  display: inline-block;
  position: absolute;
  box-shadow: 3px 8px 20px 0px rgba(0, 0, 0, 0.2);
  left: 50%;
  top: 50%;
  background: #fff;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  padding: 5px 0 0 5px;
  transform: translate(-50%, -50%) scale(0.5);
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div {
  animation: loader-image 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(1) {
  animation-delay: -0.036s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(2) {
  animation-delay: -0.072s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(3) {
  animation-delay: -0.108s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(4) {
  animation-delay: -0.144s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(5) {
  animation-delay: -0.18s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(6) {
  animation-delay: -0.216s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(7) {
  animation-delay: -0.252s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(8) {
  animation-delay: -0.288s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader .loader-image div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes loader-image {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service-loader.hidden {
  display: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service {
  display: flex;
  align-items: center;
  width: 100%;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper {
  min-width: 95px;
  max-width: 95px;
  padding-top: 152px;
  position: relative;
  border: none;
  outline: none;
  display: block;
  transition: all 0.4s;
  background: #f3f3f3;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper.square {
  padding-top: 95px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper.cover .service-image img {
  object-fit: cover;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name {
  width: 100%;
  padding-left: 20px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name .info {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0;
  font-size: 10px;
  display: block;
  margin-top: 10px;
  padding: 0;
  text-align: left;
  border: none;
  background: none;
  outline: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name .info + .info {
  margin-top: 0;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name .name {
  color: #000;
  font-weight: 500;
  font-size: 13px;
  display: block;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  text-align: left;
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .breakpoint {
  display: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .price {
  min-width: 75px;
  max-width: 75px;
  margin-left: 20px;
  color: #000;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .price span {
  font-weight: 400;
  color: #999;
  font-size: 11px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity {
  margin-left: 20px;
  max-width: 80px;
  min-width: 80px;
  display: flex;
  align-self: center;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number {
  width: 35px;
  padding: 0;
  margin: 0;
  text-align: center;
  outline: none;
  background: none;
  border-radius: 0;
  border: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number,
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-decrement,
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-increment {
  height: 20px;
  user-select: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-decrement,
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-increment {
  min-width: 20px;
  border-radius: 2px;
  background: #f3f3f3;
  color: #000;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-decrement:active,
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-increment:active {
  background: #000;
  color: #fff;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity input[type=number]::-webkit-inner-spin-button,
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity input[type=number]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity input[type=number] {
  -moz-appearance: textfield;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity.loading {
  position: relative;
  pointer-events: none;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity.loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.85;
  filter: blur(1px);
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity.loading:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../img/icons/loading.svg) center;
  background-size: cover;
  height: 18px;
  width: 18px;
  animation: loader-rotate cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s infinite;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-delete {
  padding: 0;
  background: none;
  border: none;
  display: flex;
  min-width: 15px;
  max-width: 15px;
  outline: none;
  margin-left: 20px;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-delete svg {
  width: 100%;
}
.header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper:hover .name {
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-color: #000 !important;
  text-decoration-thickness: 0.5px !important;
}
.header-bag-sidebar .sidebar-service .service-menu-wrapper {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  transition: all 0.6s;
  z-index: 222;
  height: 100vh;
  width: 100%;
}
.header-bag-sidebar .sidebar-service .service-menu-wrapper.hidden {
  transform: translateX(100%);
  z-index: 1;
}
.header-bag-sidebar .sidebar-service .service-menu-wrapper + .service-menu-wrapper {
  z-index: 444;
}
.header-bag-sidebar .sidebar-service .service-menu {
  max-height: 100vh;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink {
  display: block;
  outline: none;
  padding: 15px 20px;
  width: 100%;
  background: none;
  border: none;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink .backlink-inner {
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink .backlink-inner span {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink .backlink-inner img {
  margin-right: 15px;
  transform: rotate(180deg);
  height: 10px;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink.backlink-close {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.header-bag-sidebar .sidebar-service .service-menu .backlink.backlink-close.hidden {
  display: none;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-filters-button {
  border: none;
  border-top: 45px solid #f1f1f1;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  outline: none;
  font-weight: 500;
  padding: 15px 20px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-filters-button svg {
  margin-right: 10px;
  min-width: 14px;
  max-width: 14px;
  height: 14px;
  fill: #000;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-filters-button span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-filters-button.hidden {
  display: none;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-heading {
  border-top: 45px solid #f1f1f1;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  padding: 15px 20px;
  color: #fff;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages {
  border-top: 45px solid #f1f1f1;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper .icon {
  fill: #000;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 20px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul {
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button {
  padding: 0px 6px 1px 6px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: none;
  outline: none;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button img {
  height: 24px;
  width: 24px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button span {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li + li {
  margin-left: 5px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper + .currency-languages-wrapper {
  border-top: 0.5px solid #ccc;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list {
  list-style: none;
  border-top: 45px solid #f1f1f1;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button {
  padding: 15px 50px 15px 20px;
  width: 100%;
  background: none;
  position: relative;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a .icon,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button .icon {
  fill: #000;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a span,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button span {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a.has-child:after,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button.has-child:after {
  content: '';
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/icons/right-chevron.svg) center;
  background-size: cover;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a.background-default .icon,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button.background-default .icon {
  fill: #fff;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li a.background-default span,
.header-bag-sidebar .sidebar-service .service-menu .menu-list li button.background-default span {
  color: #fff;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-list li:not(:last-child) {
  border-bottom: 0.5px solid #ccc;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-filters-button:not(.hidden) + .menu-heading {
  border-top: none;
}
.header-bag-sidebar .sidebar-service .service-menu .menu-heading + .menu-list {
  border-top: 0.5px solid #ccc;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper {
  border-top: 45px solid #f1f1f1;
  padding: 20px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav {
  margin-top: 0;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .heading {
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .heading:not(:first-of-type) {
  margin-top: 30px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box {
  font-size: 17px;
  letter-spacing: 1px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box .box-square {
  margin-top: 7px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .range-wrapper {
  justify-content: flex-start;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .filter-nav .range-wrapper input[type=number] {
  margin: 0 10px;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .inner-footer {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .inner-footer > * + * {
  margin-left: 20px;
}
.header-bag-sidebar .sidebar-service .service-summary {
  bottom: 81px;
  right: 0;
  width: 100%;
  position: absolute;
  background: #fff;
  border-top: 1px solid #ccc;
  margin-top: 30px;
  color: #000;
  font-size: 17px;
  line-height: 24px;
  text-align: right;
  padding: 20px 42px 20px 40px;
}
.header-bag-sidebar .sidebar-service .service-footer {
  position: absolute;
  bottom: 0;
  padding: 0 40px 40px 40px;
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-bag-sidebar .sidebar-service .product-modal {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  max-height: 100vh;
  height: 100%;
  width: 100%;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner {
  padding: 40px;
  max-height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading {
  display: flex;
  justify-content: space-between;
  color: #000;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .heading {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  padding-right: 15px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close {
  height: 25px;
  min-width: 25px;
  max-width: 25px;
  border: none;
  background: none;
  position: relative;
  outline: none;
  padding: 0;
  cursor: pointer;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 25px;
  height: 1px;
  background: #000;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close span:nth-child(1) {
  transform: rotate(45deg) translateY(-50%);
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close span:nth-child(2) {
  transform: rotate(-45deg) translateY(-50%);
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer {
  margin-top: 50px;
  max-width: 380px;
  width: 100%;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer .service-image-wrapper {
  width: 100%;
  padding-top: 160%;
  display: block;
  transition: all 0.4s;
  position: relative;
  background: #f3f3f3;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer .service-image-wrapper.square {
  padding-top: 100%;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer .service-image-wrapper.cover .service-image img {
  object-fit: cover;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features {
  margin-top: 50px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features .feature-inner {
  display: flex;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features .feature-inner p {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features .feature-inner p:nth-child(2) {
  margin-left: 20px;
  font-weight: 700;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-description {
  margin-top: 45px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-description p {
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer {
  padding-top: 40px;
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer > * + * {
  margin-left: 20px;
}
.header-bag-sidebar .sidebar-service .product-modal .modal-inner.active {
  transform: none;
  opacity: 1;
  visibility: visible;
}
.header-bag-sidebar .sidebar-service .product-modal.active {
  opacity: 1;
  visibility: visible;
}
.header-bag-sidebar .sidebar-service.active {
  transform: translateX(0);
}
.header-bag-sidebar.favorite .sidebar-service .service-inner {
  max-height: calc(100vh - 236px);
}
.header-bag-sidebar.favorite .sidebar-service .service-footer {
  padding-top: 40px;
  border-top: 1px solid #ccc;
}
.header-bag-sidebar.active {
  z-index: 555;
  visibility: visible;
  opacity: 1;
}
nav.section-header {
  transition: all 0.4s;
}
nav.section-header .header-top {
  padding: 13px 0;
  border-bottom: 0.5px solid #ccc;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-top p {
  font-size: 9.2px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
nav.section-header .header-top .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-top .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #000;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-top .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-top .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-top .nav-button:hover .button-bar,
nav.section-header .header-top .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .header-top .top-service {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-currency .dropdown-button {
  height: 27px;
  padding: 5px 18px 5px 5px;
  border: none;
  background: #e9e9e9;
  border-radius: 3px;
  outline: none;
  position: relative;
}
nav.section-header .header-top .top-currency .dropdown-button span {
  font-size: 9.2px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}
nav.section-header .header-top .top-currency .dropdown-button svg {
  position: absolute;
  top: 50%;
  right: 6px;
  fill: #000;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  transform: translateY(-50%) rotate(90deg);
}
nav.section-header .header-top .top-currency .dropdown-menu {
  padding: 0;
  min-width: auto;
}
nav.section-header .header-top .top-currency .dropdown-menu li .dropdown-item {
  padding: 5px 16px 5px 5px;
  outline: none;
}
nav.section-header .header-top .top-currency .dropdown-menu li .dropdown-item span {
  font-size: 9.2px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}
nav.section-header .header-top .top-currency + .top-languages {
  margin-left: 5px;
}
nav.section-header .header-top .top-currency + .top-social-media:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-top .top-currency + .top-navigation:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-top .top-languages .dropdown-button {
  height: 27px;
  padding: 0px 18px 2px 5px;
  border: none;
  background: #e9e9e9;
  border-radius: 3px;
  outline: none;
  position: relative;
}
nav.section-header .header-top .top-languages .dropdown-button img {
  height: 21px;
  width: 21px;
}
nav.section-header .header-top .top-languages .dropdown-button svg {
  position: absolute;
  top: 50%;
  right: 6px;
  fill: #000;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  transform: translateY(-50%) rotate(90deg);
}
nav.section-header .header-top .top-languages .dropdown-menu {
  padding: 0;
  min-width: auto;
}
nav.section-header .header-top .top-languages .dropdown-menu li .dropdown-item {
  padding: 0px 16px 2px 5px;
  outline: none;
}
nav.section-header .header-top .top-languages .dropdown-menu li .dropdown-item img {
  height: 21px;
  width: 21px;
}
nav.section-header .header-top .top-languages + .top-social-media:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-top .top-languages + .top-navigation:before {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: inline-block;
  margin: 0 20px 0 24px;
}
nav.section-header .header-top .top-social-media {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-social-media li a svg {
  height: 16px;
  width: 16px;
  fill: #000;
  transition: all 0.4s;
}
nav.section-header .header-top .top-social-media li:not(:last-child) {
  margin-right: 10px;
}
nav.section-header .header-top .top-social-media:after {
  content: '';
  border-right: 0.5px solid #ccc;
  height: 14px;
  display: block;
  margin: 0 20px;
}
nav.section-header .header-top .top-navigation {
  display: flex;
  align-items: center;
}
nav.section-header .header-top .top-navigation li a {
  font-size: 9.2px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
nav.section-header .header-top .top-navigation li:not(:last-child) {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 0.5px solid #ccc;
}
nav.section-header .header-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 30px 0;
}
nav.section-header .header-management .management-logo {
  display: block;
}
nav.section-header .header-management .management-logo .logo-text {
  font-size: 23px;
  font-weight: 600;
  color: #000;
  letter-spacing: -0.9px;
}
nav.section-header .header-management .management-logo .logo-text span {
  font-weight: 700;
  color: #c31432;
  font-size: 30px;
  line-height: 10px;
}
nav.section-header .header-management .management-logo .logo-image {
  min-width: 200px;
  max-width: 200px;
  position: relative;
}
nav.section-header .header-management .management-logo .logo-image img {
  width: 100%;
}
nav.section-header .header-management .management-logo .logo-image.image-size-3 {
  min-width: 125px;
  max-width: 125px;
}
nav.section-header .header-management .management-logo .logo-image.image-size-6 {
  min-width: 150px;
  max-width: 150px;
}
nav.section-header .header-management .management-logo .logo-image.image-size-9 {
  min-width: 175px;
  max-width: 175px;
}
nav.section-header .header-management .management-logo .logo-image.image-size-12 {
  min-width: 200px;
  max-width: 200px;
}
nav.section-header .header-management .management-offer {
  position: relative;
}
nav.section-header .header-management .management-offer button {
  height: 37px;
  border-radius: 20px;
  border: none;
  padding: 0 20px;
  outline: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}
nav.section-header .header-management .management-offer button svg {
  margin-left: 10px;
  fill: #fff;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  transform: rotate(90deg);
}
nav.section-header .header-management .management-offer .offer-dropdown {
  position: absolute;
  min-width: 310px;
  z-index: -1;
  bottom: 0;
  left: 0;
  transform: translate(0, 100%);
  padding-top: 20px;
  opacity: 0;
  visibility: hidden;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner {
  border-top-width: 2px;
  border-top-style: solid;
  padding: 10px 0 10px 10px;
  background: #fff;
  box-shadow: 5px 8px 32px 0px rgba(0, 0, 0, 0.18);
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item {
  position: relative;
  padding: 0;
  background: none;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item .item-heading {
  padding: 15px 40px 15px 18px;
  background: none;
  font-size: 17px;
  display: block;
  color: #000;
  letter-spacing: 1px;
  border-right: 10px solid #fff;
  font-weight: 500;
  line-height: 13px;
  transition: all 0.4s;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item .dropdown-menu-inner {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  padding: 10px;
  background: #fff;
  z-index: 444;
  box-shadow: 15px 8px 20px 0px rgba(0, 0, 0, 0.13);
  opacity: 0;
  visibility: hidden;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item .dropdown-menu-inner .menu-item {
  display: block;
  font-size: 14px;
  color: #000;
  padding: 10px;
  letter-spacing: 0.7px;
  font-weight: 500;
  line-height: 13px;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item.active,
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item:hover {
  background: #f3f3f3;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item.active .dropdown-menu-inner {
  opacity: 1;
  visibility: visible;
}
nav.section-header .header-management .management-offer .offer-dropdown .offer-dropdown-inner .dropdown-item.has-child .item-heading:after {
  content: '';
  height: 6px;
  width: 6px;
  background: url('../img/icons/right-chevron.svg') center;
  background-size: cover;
  opacity: 0.5;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
nav.section-header .header-management .management-offer .offer-dropdown.active {
  opacity: 1;
  visibility: visible;
  z-index: 333;
}
nav.section-header .header-management .management-breakpoint {
  display: none;
}
nav.section-header .header-management .management-search-engine {
  border: 0.5px solid #000;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;
}
nav.section-header .header-management .management-search-engine input {
  width: 100%;
  min-width: 470px;
  height: 40px;
  padding: 10px 15px 10px 15px;
  border: none;
  background: none;
  font-size: 11px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
  transition: all 0.4s;
}
nav.section-header .header-management .management-search-engine input:focus {
  outline: none;
}
nav.section-header .header-management .management-search-engine input::placeholder {
  font-size: 10px;
  color: #000;
  opacity: 0.5;
  font-weight: 500;
  letter-spacing: 1px;
}
nav.section-header .header-management .management-search-engine .btn-search {
  height: 40px;
  min-width: 40px;
  background: #000;
  border: none;
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(1px);
  transition: background 0.4s;
}
nav.section-header .header-management .management-search-engine .btn-search svg {
  height: 15px;
  width: 15px;
  fill: #fff;
}
nav.section-header .header-management .management-search-engine .btn-search:hover {
  background: #000000;
}
nav.section-header .header-management .management-service {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-service .service-icons a,
nav.section-header .header-management .management-service .service-icons button {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  position: relative;
}
nav.section-header .header-management .management-service .service-icons a svg,
nav.section-header .header-management .management-service .service-icons button svg {
  height: 20px;
  width: 20px;
  fill: #444;
  transition: all 0.4s;
}
nav.section-header .header-management .management-service .service-icons a .quantity-badge,
nav.section-header .header-management .management-service .service-icons button .quantity-badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 500;
  color: #fff;
  top: -7px;
  left: 15px;
}
nav.section-header .header-management .management-service .service-icons a .quantity-badge.pulse-badge:after,
nav.section-header .header-management .management-service .service-icons button .quantity-badge.pulse-badge:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0.8);
  display: block;
  width: 20px;
  height: 20px;
  background-color: #c31432;
  filter: blur(2px);
  border-radius: 15px;
  animation: pulse-badge 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse-badge {
  40% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
    transform: scale(1);
  }
  60% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
nav.section-header .header-management .management-service .service-icons a .cost-line,
nav.section-header .header-management .management-service .service-icons button .cost-line {
  align-self: flex-end;
  transform: translateY(3px);
  font-size: 11px;
  margin-left: 15px;
  color: #444;
}
nav.section-header .header-management .management-service .service-icons a .cost-line small,
nav.section-header .header-management .management-service .service-icons button .cost-line small {
  letter-spacing: 0;
  font-size: 11px;
}
nav.section-header .header-management .management-service .service-icons a:hover,
nav.section-header .header-management .management-service .service-icons button:hover {
  color: #000;
}
nav.section-header .header-management .management-service .service-icons a:hover svg,
nav.section-header .header-management .management-service .service-icons button:hover svg {
  fill: #000;
}
nav.section-header .header-management .management-service .service-icons:nth-child(2) {
  margin-left: 20px;
}
nav.section-header .header-management .management-service .service-icons:nth-child(2) a .quantity-badge {
  left: 13px;
}
nav.section-header .header-management .management-service .service-nav-button {
  display: none;
}
nav.section-header .header-management .management-service .service-nav-button .nav-button {
  transform: translateY(2px);
  margin-right: -10px;
  padding: 10px;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
}
nav.section-header .header-management .management-service .service-nav-button .nav-button .button-bar {
  height: 1.5px;
  width: 25px;
  background: #000;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-management .management-service .service-nav-button .nav-button .button-bar:nth-child(2) {
  margin-top: 5px;
}
nav.section-header .header-management .management-service .service-nav-button .nav-button .button-bar:nth-child(3) {
  margin-top: 5px;
}
nav.section-header .header-management .management-service .service-nav-button .nav-button .button-name {
  font-size: 8px;
  letter-spacing: 0.7px;
  margin-top: 5px;
  display: block;
  font-weight: 500;
  color: #000;
  line-height: 8px;
  text-transform: uppercase;
}
section.section-welcome {
  transition: all 0.4s;
}
section.section-welcome.no-js {
  display: none;
}
section.section-welcome .welcome-item {
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
}
section.section-welcome .welcome-item .item-image {
  width: 43%;
  height: 570px;
  position: relative;
  z-index: 111;
}
section.section-welcome .welcome-item .item-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
  transition: all 0.4s;
}
section.section-welcome .welcome-item .item-image img.cover {
  object-fit: cover;
}
section.section-welcome .welcome-item .item-description {
  width: 50%;
  align-self: center;
  transition: all 0.4s;
  position: relative;
  color: #000;
}
section.section-welcome .welcome-item .item-description:before {
  content: '';
  top: 50%;
  transform: translateY(-50%);
  height: 300px;
  width: 300px;
  left: -240px;
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #f9f9f9;
}
section.section-welcome .welcome-item .item-description .info {
  position: relative;
  font-size: 9.2px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
section.section-welcome .welcome-item .item-description .text {
  font-size: 39px;
  line-height: 45px;
  max-width: 400px;
  margin-bottom: 20px;
  position: relative;
}
section.section-welcome .welcome-item .item-description .description {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  max-width: 400px;
  line-height: 24px;
  opacity: 0.7;
  margin-bottom: 40px;
}
section.section-welcome .welcome-item .item-description.white {
  color: #fff;
}
section.section-welcome .welcome-item.full-width-background {
  height: 570px;
  position: relative;
}
section.section-welcome .welcome-item.full-width-background .item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: auto;
}
section.section-welcome .welcome-item.full-width-background .item-description {
  width: 100%;
  position: relative;
  text-align: center;
}
section.section-welcome .welcome-item.full-width-background .item-description:before {
  content: none;
}
section.section-welcome .welcome-item.full-width-background .item-description .text {
  margin: 0 auto 20px auto;
}
section.section-welcome .welcome-item.full-width-background .item-description .description {
  margin: 0 auto 40px auto;
}
section.section-welcome .welcome-item.full-width-background .item-description .btn {
  background: #f3f3f3;
  padding: 5px 12px;
  border-radius: 15px;
}
section.section-welcome .welcome-item.full-width-background .item-description .btn.pulse-animate:after {
  left: 12px;
  top: 5px;
}
section.section-welcome .welcome-item.full-width-background.auto-height {
  height: auto;
}
section.section-welcome .welcome-item.full-width-background.auto-height .item-image {
  position: relative;
  height: auto;
  top: auto;
  left: auto;
}
section.section-welcome .welcome-item.full-width-background.auto-height .item-image img {
  width: 100%;
  height: auto;
}
section.section-welcome .welcome-item.full-width-background.auto-height .item-description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.section-welcome .owl-carousel-welcome {
  position: relative;
}
section.section-welcome .owl-carousel-welcome .welcome-item .item-image img.position-animate {
  opacity: 0;
}
section.section-welcome .owl-carousel-welcome .welcome-item .item-description.position-animate {
  transform: translateX(20px);
  opacity: 0;
}
section.section-welcome .owl-carousel-welcome .owl-dots {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
section.section-welcome .owl-carousel-welcome .owl-dots .owl-dot {
  margin: 5px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.4s;
  outline: none;
}
section.section-welcome .owl-carousel-welcome .owl-dots .owl-dot span {
  background-color: #222;
  min-width: 6px;
  max-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0;
  transition: all 0.4s;
}
section.section-welcome .owl-carousel-welcome .owl-dots .owl-dot.active {
  border: 1px solid #000;
  background: #fff;
}
section.section-welcome .owl-carousel-welcome .owl-dots .owl-dot.active span {
  background-color: #000;
}
section.section-welcome .owl-carousel-welcome .owl-nav {
  position: absolute !important;
  margin-top: 0;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}
section.section-welcome .owl-carousel-welcome .owl-nav .owl-prev,
section.section-welcome .owl-carousel-welcome .owl-nav .owl-next {
  background-color: #f3f3f3;
  background-image: url(../img/icons/right-chevron.svg);
  background-position: center;
  background-size: 45%;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  display: block;
  height: 30px;
  width: 30px;
  outline: none;
  border-radius: 2px;
}
section.section-welcome .owl-carousel-welcome .owl-nav .owl-prev {
  transform: rotate(-180deg);
  margin-right: 5px;
}
section.section-welcome .welcome-mobile-item {
  display: none;
}
section.section-welcome .welcome-mobile-item img {
  width: 100%;
}
section.section-welcome .owl-carousel-welcome-mobile {
  display: none;
}
section.section-welcome + section.section-categories {
  margin-top: 60px;
}
section.section-categories {
  margin-top: 20px;
  transition: all 0.4s;
}
section.section-categories .categories-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-categories .categories-flexbox .flexbox-service {
  position: relative;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner {
  position: absolute;
  border-radius: 3px;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner .inner-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.4s;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner .inner-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  transition: all 0.4s;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner .inner-name .name-box {
  text-align: center;
  width: 100%;
  padding: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner:hover .inner-cover {
  opacity: 1;
}
section.section-categories .categories-flexbox .flexbox-service .service-inner:hover .inner-name {
  bottom: 20px;
}
section.section-categories .categories-flexbox .flexbox-service.service-wrapper {
  padding-top: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.section-categories .categories-flexbox .flexbox-service.service-wrapper .service-inner {
  position: relative;
  height: auto;
  width: auto;
  width: 100%;
  padding-top: 100%;
}
section.section-categories .categories-flexbox.version-1 .flexbox-service {
  width: 32%;
  padding-top: 65.9%;
}
section.section-categories .categories-flexbox.version-2 .flexbox-service {
  width: 23.5%;
  padding-top: 49%;
}
section.section-categories .categories-flexbox.version-2 .flexbox-service .service-inner .inner-name .name-box {
  padding: 10px;
  font-size: 14px;
}
section.section-categories .categories-flexbox.version-3 .flexbox-service {
  width: 23.5%;
  padding-top: 49%;
}
section.section-categories .categories-flexbox.version-3 .flexbox-service .service-inner .inner-name .name-box {
  padding: 10px;
  font-size: 14px;
}
section.section-categories .categories-flexbox.version-3 .flexbox-service.service-double {
  width: 49%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
}
section.section-categories .categories-flexbox.version-3 .flexbox-service.service-double .service-inner {
  width: 47.9%;
  padding-top: 47.9%;
}
section.section-categories .categories-flexbox.version-3 .flexbox-service.service-double .service-inner.vertical-100 {
  width: 100%;
}
section.section-advantages {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-advantages .advantages-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-advantages .advantages-flexbox .flexbox-service {
  text-align: center;
  width: 25%;
  padding: 0 10px;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-icon {
  height: 40px;
  width: 40px;
}
section.section-advantages .advantages-flexbox .flexbox-service .service-name {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  text-transform: none;
}
section.section-offer {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-offer .section-heading {
  text-align: center;
}
section.section-offer .section-heading h2 {
  font-size: 34px;
  font-weight: 400;
  color: #000;
}
section.section-offer .section-heading p {
  max-width: 600px;
  margin: 10px auto 0 auto;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.section-offer .nav-tabs {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: none;
}
section.section-offer .nav-tabs .nav-item .nav-link {
  padding: 0;
  margin: 5px 10px;
  border: none;
  border-radius: 0;
  background: none;
  color: #000 !important;
  opacity: 0.5;
  transition: 0.4s;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: auto;
  font-size: 14px;
  text-transform: none;
}
section.section-offer .nav-tabs .nav-item .nav-link.active {
  color: inherit !important;
  opacity: 1;
}
section.section-offer .products-flexbox {
  margin-top: 10px;
}
section.section-cta {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-cta.no-js {
  display: none;
}
section.section-cta .cta-item {
  position: relative;
}
section.section-cta .cta-item .item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
section.section-cta .cta-item .item-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
  transition: all 0.4s;
}
section.section-cta .cta-item .item-image img.cover {
  object-fit: cover;
}
section.section-cta .cta-item .item-description {
  padding: 70px 40px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  color: #000;
}
section.section-cta .cta-item .item-description .info {
  position: relative;
  font-size: 9.2px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
section.section-cta .cta-item .item-description .text {
  font-size: 39px;
  line-height: 45px;
  max-width: 520px;
  margin: 0 auto 20px auto;
  position: relative;
}
section.section-cta .cta-item .item-description .description {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  max-width: 400px;
  line-height: 24px;
  opacity: 0.85;
  margin: 0 auto 40px auto;
}
section.section-cta .cta-item .item-description .btn {
  background: #f3f3f3;
  padding: 5px 12px;
  border-radius: 15px;
}
section.section-cta .cta-item .item-description .btn.pulse-animate:after {
  left: 12px;
  top: 5px;
}
section.section-cta .cta-item .item-description.white {
  color: #fff;
}
section.section-cta .cta-item.auto-height .item-image {
  position: relative;
  height: auto;
  top: auto;
  left: auto;
}
section.section-cta .cta-item.auto-height .item-image img {
  width: 100%;
  height: auto;
}
section.section-cta .cta-item.auto-height .item-description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.section-cta .owl-carousel-cta {
  background: #f3f3f3;
  position: relative;
}
section.section-cta .owl-carousel-cta .owl-dots {
  display: none;
}
section.section-cta .owl-carousel-cta .owl-nav {
  position: absolute !important;
  margin-top: 0;
  bottom: 17px;
  right: 20px;
  display: flex;
  align-items: center;
}
section.section-cta .owl-carousel-cta .owl-nav .owl-prev,
section.section-cta .owl-carousel-cta .owl-nav .owl-next {
  background-color: #f3f3f3;
  background-image: url(../img/icons/right-chevron.svg);
  background-position: center;
  background-size: 45%;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  display: block;
  height: 30px;
  width: 30px;
  outline: none;
  border-radius: 2px;
}
section.section-cta .owl-carousel-cta .owl-nav .owl-prev {
  transform: rotate(-180deg);
  margin-right: 5px;
}
section.section-blog {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-blog.subpage {
  background: #fff;
  margin-top: 0;
  padding: 70px 0 100px 0;
}
section.section-blog .section-heading {
  text-align: center;
}
section.section-blog .section-heading h1,
section.section-blog .section-heading h2 {
  font-size: 34px;
  font-weight: 400;
  color: #000;
}
section.section-blog .section-heading h1 a,
section.section-blog .section-heading h2 a {
  color: inherit;
}
section.section-blog .section-heading p {
  max-width: 600px;
  margin: 10px auto 0 auto;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.section-blog .blog-categories {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
section.section-blog .blog-categories > * {
  border-radius: 30px;
  margin: 5px;
}
section.section-blog .btn-more-products-wrapper {
  margin-top: 80px;
}
section.section-blog .btn-more-products-wrapper .more-products-counter {
  margin-bottom: 35px;
}
section.section-blog .btn-more-products-wrapper .more-products-counter .number {
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
}
section.section-blog .btn-more-products-wrapper .more-products-counter .line {
  margin: 0 auto;
  width: 170px;
  height: 2px;
  background: #eee;
  position: relative;
}
section.section-blog .btn-more-products-wrapper .more-products-counter .line .line-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #333;
}
section.section-industries {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-industries .section-heading {
  text-align: center;
}
section.section-industries .section-heading h2 {
  font-size: 34px;
  font-weight: 400;
  color: #000;
}
section.section-industries .section-heading h2 a {
  color: inherit;
}
section.section-industries .section-heading p {
  max-width: 600px;
  margin: 10px auto 0 auto;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.section-industries .industries-flexbox {
  max-width: 1100px;
  margin: 25px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
section.section-industries .industries-flexbox .flexbox-service {
  margin: 10px;
  padding: 10px 20px;
  background: #f3f3f3;
  color: #000;
  border-radius: 2px;
  font-size: 16px;
}
section.section-industries .industries-flexbox .flexbox-service:hover {
  color: #fff;
}
section.section-newsletter {
  margin-top: 100px;
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;
}
section.section-newsletter.subpage {
  margin-top: 0;
  padding-top: 0;
}
section.section-newsletter .section-heading {
  text-align: center;
}
section.section-newsletter .section-heading h2 {
  font-size: 34px;
  font-weight: 400;
  color: #000;
}
section.section-newsletter .section-heading h2 a {
  color: inherit;
}
section.section-newsletter .section-heading p {
  max-width: 600px;
  margin: 10px auto 0 auto;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.section-newsletter .newsletter-input {
  border: 0.5px solid #000;
  height: 40px;
  width: 580px;
  margin: 50px auto 0 auto;
  max-width: 100%;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}
section.section-newsletter .newsletter-input input {
  width: 100%;
  height: 40px;
  padding: 10px 15px 10px 15px;
  border: none;
  background: none;
  font-size: 11px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
  transition: all 0.4s;
}
section.section-newsletter .newsletter-input input:focus {
  outline: none;
}
section.section-newsletter .newsletter-input input::placeholder {
  font-size: 9.2px;
  color: #000;
  opacity: 0.5;
  font-weight: 500;
  letter-spacing: 1px;
}
section.section-newsletter .newsletter-input .btn-search {
  height: 40px;
  min-width: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
  padding: 0 30px;
  white-space: nowrap;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(1px);
  transition: background 0.4s;
}
section.section-newsletter .newsletter-input .btn-search:hover {
  background: #000000;
}
section.section-newsletter .input-alert {
  text-align: center;
  margin-top: 7px;
  font-size: 11px;
  color: #dc3545;
}
section.section-newsletter .checkbox-wrapper-outer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
section.kategoria-section-content {
  transition: all 0.4s;
  padding: 70px 0 100px 0;
  background: #fff;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 20%;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
  margin-bottom: 25px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  font-size: 11px;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  width: 75%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-categories-button {
  display: none;
  align-items: center;
  height: 29px;
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 0 18px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-categories-button svg {
  fill: #fff;
  height: 14px;
  min-width: 14px;
  max-width: 14px;
  margin-right: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-categories-button span {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  align-self: flex-start;
  font-size: 11px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper {
  position: relative;
  margin-left: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper:before {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 999;
  transform: translateX(-50%);
  border-left: 1px solid #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid {
  border-radius: 30px;
  display: flex;
  border: 1px solid #999;
  overflow: hidden;
  height: 29px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid a,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid button {
  margin: 0;
  padding: 0;
  border: none;
  background: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-1px);
  height: 29px;
  width: 35px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid a svg,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid button svg {
  width: 15px;
  height: 15px;
  fill: #999;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid a:hover svg,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid button:hover svg {
  fill: #000;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid a:nth-child(1),
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid button:nth-child(1) {
  margin-left: 5px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid a:nth-child(2),
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper .heading-grid button:nth-child(2) {
  margin-right: 5px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading {
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading .heading-backlink {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 0.5px solid #ccc;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading .heading-backlink span {
  font-size: 17px;
  font-weight: 500;
  color: #666;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading .heading-backlink img {
  margin-right: 10px;
  transform: rotate(180deg);
  height: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading .heading-name {
  padding: 25px 0;
  font-size: 22px;
  font-weight: 500;
  color: #000;
  line-height: 25px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper {
  margin-top: 80px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper .more-products-counter {
  margin-bottom: 35px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper .more-products-counter .number {
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper .more-products-counter .line {
  margin: 0 auto;
  width: 170px;
  height: 2px;
  background: #eee;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper .more-products-counter .line .line-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #333;
}
section.kategoria-section-content .content-description {
  border-top: 0.5px solid #ccc;
  padding-top: 90px;
  margin-top: 100px;
}
section.kategoria-section-content .content-description .description-inner {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
section.kategoria-section-content .content-description .description-inner h1,
section.kategoria-section-content .content-description .description-inner h2 {
  font-size: 34px;
  font-weight: 400;
  color: #000;
}
section.kategoria-section-content .content-description .description-inner h1 a,
section.kategoria-section-content .content-description .description-inner h2 a {
  color: inherit;
}
section.kategoria-section-content .content-description .description-inner p {
  margin-top: 10px;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
.category-nav-wrapper {
  position: relative;
}
.category-nav-wrapper .category-nav li {
  list-style: none;
}
.category-nav-wrapper .category-nav li a {
  font-size: 17px;
  display: block;
  color: #000;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 15px;
  transition: all 0.4s;
}
.category-nav-wrapper .category-nav li.active > a {
  font-weight: 700;
}
.category-nav-wrapper .category-nav li:not(:last-child) {
  padding-bottom: 20px;
}
.category-nav-wrapper .category-nav li ul {
  margin-top: 17px;
  padding-left: 17px;
}
.category-nav-wrapper .category-nav li ul li {
  padding-bottom: 0 !important;
}
.category-nav-wrapper .category-nav li ul li a {
  padding: 5px 0;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #000;
}
.category-nav-wrapper .category-nav li ul li ul {
  margin: 14px 0;
}
.category-nav-wrapper .category-nav > li > a {
  line-height: 19px;
}
.category-nav-wrapper .filter-nav {
  margin-top: 65px;
}
.category-nav-wrapper .filter-nav .heading:not(:first-of-type) {
  margin-top: 65px;
}
.category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box {
  font-size: 17px;
  letter-spacing: 1px;
}
.category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box .box-square {
  margin-top: 7px;
}
.category-nav-wrapper .filter-nav .range-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-nav-wrapper .filter-nav .range-wrapper input[type=number] {
  border: none;
  max-width: 100px;
  margin: 0 10px;
  outline: none;
  text-align: center;
  font-size: 14px;
  height: 30px;
  border-radius: 2px;
  background: #f3f3f3;
  width: 100%;
  -moz-appearance: textfield;
}
.category-nav-wrapper .filter-nav .range-wrapper input[type=number]::-webkit-outer-spin-button,
.category-nav-wrapper .filter-nav .range-wrapper input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.category-nav-wrapper .filter-nav .range-wrapper input[type=number]:invalid,
.category-nav-wrapper .filter-nav .range-wrapper input[type=number]:out-of-range {
  border: 1px solid #ff6347;
}
.category-nav-wrapper .filter-nav .range-wrapper span {
  display: block;
  min-width: 12px;
}
.category-nav-wrapper .filter-nav .range-wrapper p {
  font-size: 13px;
  color: #000;
}
.category-nav-wrapper .filter-nav .buttons-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-nav-wrapper .filter-nav .buttons-wrapper .btn-clear {
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  font-size: 12px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.category-nav-wrapper .filter-nav .buttons-wrapper .btn-filter {
  padding: 0;
  border: none;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  padding: 3px 10px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.4s;
}
section.produkt-section-content {
  transition: all 0.4s;
  padding: 70px 0 100px 0;
  background: #fff;
}
section.produkt-section-content .heading {
  display: none;
}
section.produkt-section-content .heading .heading-backlink {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 0.5px solid #ccc;
}
section.produkt-section-content .heading .heading-backlink span {
  font-size: 17px;
  font-weight: 500;
  color: #666;
}
section.produkt-section-content .heading .heading-backlink img {
  margin-right: 10px;
  transform: rotate(180deg);
  height: 10px;
}
section.produkt-section-content .content-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.produkt-section-content .content-inner .product-gallery {
  width: 38%;
}
section.produkt-section-content .content-inner .product-gallery img {
  width: 100%;
}
section.produkt-section-content .content-inner .product-gallery .lSSlideWrapper {
  position: relative;
}
section.produkt-section-content .content-inner .product-gallery .lSAction a {
  margin-top: 0;
  transition: all 0.4s;
  background: none;
}
section.produkt-section-content .content-inner .product-gallery .lSAction .lSPrev {
  width: 25px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: none;
}
section.produkt-section-content .content-inner .product-gallery .lSAction .lSPrev:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background: url('../library/lightslider-master/dist/img/right-chevron.svg') center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
section.produkt-section-content .content-inner .product-gallery .lSAction .lSNext {
  width: 25px;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  transform: none;
}
section.produkt-section-content .content-inner .product-gallery .lSAction .lSNext:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background: url('../library/lightslider-master/dist/img/right-chevron.svg') center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
section.produkt-section-content .content-inner .product-gallery .lSGallery {
  margin-top: 23px !important;
}
section.produkt-section-content .content-inner .product-gallery .lSGallery li {
  border-radius: 0;
  opacity: 0.6;
  transition: all 0.4s;
}
section.produkt-section-content .content-inner .product-gallery .lSGallery li a {
  width: 100%;
  padding-top: 100%;
  display: block;
  position: relative;
}
section.produkt-section-content .content-inner .product-gallery .lSGallery li a img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-position: center;
  object-fit: cover;
}
section.produkt-section-content .content-inner .product-gallery .lSGallery li.active,
section.produkt-section-content .content-inner .product-gallery .lSGallery li:hover {
  opacity: 1;
}
section.produkt-section-content .content-inner .product-info {
  width: 55%;
}
section.produkt-section-content .content-inner .product-info .info-alert {
  padding: 20px;
  background: #f9f9f9;
  border-top: 2px solid crimson;
  margin-bottom: 30px;
}
section.produkt-section-content .content-inner .product-info .info-alert .alert-name {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-alert .alert-content {
  margin-top: 10px;
}
section.produkt-section-content .content-inner .product-info .info-alert .alert-content h2 {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}
section.produkt-section-content .content-inner .product-info .info-alert .alert-content p,
section.produkt-section-content .content-inner .product-info .info-alert .alert-content li {
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-alert .alert-content ul {
  padding-left: 17px;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper {
  display: flex;
  margin-bottom: 20px;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge {
  width: 90px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #fff;
  border-radius: 2px;
  margin-bottom: 6px;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge.sale {
  background: crimson;
  display: none;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge.own {
  background: #ef7b0a;
  display: none;
}
section.produkt-section-content .content-inner .product-info .badge-wrapper .badge:not(:last-child) {
  margin-right: 10px;
}
section.produkt-section-content .content-inner .product-info h1 {
  max-width: 650px;
  font-size: 28px;
  font-weight: 400;
  color: #000;
}
section.produkt-section-content .content-inner .product-info .info-producer {
  margin-top: 10px;
}
section.produkt-section-content .content-inner .product-info .info-producer .producer-inner {
  display: flex;
}
section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p a {
  color: dodgerblue;
}
section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p a:hover {
  text-decoration: underline;
}
section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p:nth-child(2) {
  margin-left: 20px;
  font-weight: 700;
}
section.produkt-section-content .content-inner .product-info .info-features {
  margin-top: 40px;
}
section.produkt-section-content .content-inner .product-info .info-features > *:not(:first-child) {
  margin-top: 10px;
}
section.produkt-section-content .content-inner .product-info .info-features .feature-inner {
  display: flex;
}
section.produkt-section-content .content-inner .product-info .info-features .feature-inner p {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-features .feature-inner p:nth-child(2) {
  margin-left: 20px;
  font-weight: 700;
}
section.produkt-section-content .content-inner .product-info .info-features .select-wrapper-flexbox {
  display: flex;
  align-items: center;
}
section.produkt-section-content .content-inner .product-info .info-features .select-wrapper-flexbox label {
  opacity: 0.7;
  text-transform: uppercase;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
  white-space: nowrap;
  margin-bottom: 0;
  margin-right: 20px;
}
section.produkt-section-content .content-inner .product-info .info-price {
  margin-top: 40px;
}
section.produkt-section-content .content-inner .product-info .info-price .price-value {
  color: #000;
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
section.produkt-section-content .content-inner .product-info .info-price .price-value small {
  font-size: 28px;
  font-weight: 400;
  color: #999;
  margin-right: 7px;
  position: relative;
  display: none;
}
section.produkt-section-content .content-inner .product-info .info-price .price-value small:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 0.5px;
  background: #666;
}
section.produkt-section-content .content-inner .product-info .info-price .price-value span {
  font-weight: 400;
  color: #999;
  font-size: 18px;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability {
  margin-top: 10px;
  padding: 10px;
  background: #f9f9f9;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner {
  display: flex;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner p {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner p:nth-child(2) {
  margin-left: 10px;
  font-weight: 700;
  position: relative;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner p:nth-child(2):after {
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner.red p:nth-child(2):after {
  background: crimson;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner.green p:nth-child(2):after {
  background: olivedrab;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-comment {
  margin-top: 5px;
}
section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-comment p {
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-add {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity {
  display: flex;
  align-self: center;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number {
  width: 70px;
  padding: 0;
  margin: 0;
  text-align: center;
  outline: none;
  background: none;
  border-radius: 0;
  border: none;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number,
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-decrement,
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-increment {
  height: 40px;
  user-select: none;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-decrement,
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-increment {
  min-width: 40px;
  border-radius: 2px;
  background: #f3f3f3;
  color: #000;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-decrement:active,
section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number-increment:active {
  background: #000;
  color: #fff;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity input[type=number]::-webkit-inner-spin-button,
section.produkt-section-content .content-inner .product-info .info-add .service-quantity input[type=number]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.produkt-section-content .content-inner .product-info .info-add .service-quantity input[type=number] {
  -moz-appearance: textfield;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-fill {
  margin-left: 30px;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-empty {
  margin-left: 30px;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-empty svg {
  transition: all 0.4s;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-empty svg path {
  transition: all 0.4s;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-empty svg path:nth-child(1) {
  fill: #fff;
}
section.produkt-section-content .content-inner .product-info .info-add .btn-empty.checked svg path:nth-child(1),
section.produkt-section-content .content-inner .product-info .info-add .btn-empty:hover svg path:nth-child(1) {
  fill: #e9b9b9;
}
section.produkt-section-content .content-inner .product-info .info-codes {
  padding-top: 35px;
  margin-top: 40px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.produkt-section-content .content-inner .product-info .info-codes .code-inner {
  margin-top: 5px;
  display: flex;
  margin-right: 30px;
}
section.produkt-section-content .content-inner .product-info .info-codes .code-inner p {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-codes .code-inner p a {
  color: dodgerblue;
}
section.produkt-section-content .content-inner .product-info .info-codes .code-inner p a:hover {
  text-decoration: underline;
}
section.produkt-section-content .content-inner .product-info .info-codes .code-inner p:nth-child(2) {
  margin-left: 10px;
  font-weight: 700;
}
section.produkt-section-content .content-inner .product-info .info-description {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #e5e5e5;
}
section.produkt-section-content .content-inner .product-info .info-description .description-heading {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}
section.produkt-section-content .content-inner .product-info .info-description img {
  max-width: 100% !important;
}
section.produkt-section-content .content-inner .product-info .info-description p,
section.produkt-section-content .content-inner .product-info .info-description li {
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.produkt-section-content .content-inner .product-info .info-description ul {
  padding-left: 17px;
}
section.produkt-section-content .content-inner .product-info .info-download {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #e5e5e5;
}
section.produkt-section-content .content-inner .product-info .info-download .download-heading {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}
section.produkt-section-content .content-inner .product-info .info-download ul li a {
  display: flex;
  align-items: center;
}
section.produkt-section-content .content-inner .product-info .info-download ul li a svg {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  fill: #c31432;
  margin-right: 10px;
}
section.produkt-section-content .content-inner .product-info .info-download ul li a span {
  font-size: 14px;
  color: #000;
  text-decoration: underline;
  transition: all 0.4s;
}
section.produkt-section-content .content-inner .product-info .info-download ul li a:hover span {
  color: #c31432;
}
section.produkt-section-content .content-inner .product-info .info-download ul li + li {
  margin-top: 5px;
}
section.produkt-section-content .content-inner .product-info.promotion .promotion {
  display: flex !important;
}
section.produkt-section-content .content-inner .product-info.promotion .price-value {
  color: tomato;
}
section.produkt-section-content .content-inner .product-info.promotion .price-value small {
  display: inline-block;
}
section.produkt-section-content .content-inner .product-info.new .new {
  display: flex !important;
}
section.produkt-section-content .content-inner .product-info.bestseller .bestseller {
  display: flex !important;
}
section.produkt-section-content .content-inner .product-info.sale .sale {
  display: flex !important;
}
section.produkt-section-content .content-inner .product-info.own .own {
  display: flex !important;
}
section.static-page-section-content {
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;
}
section.static-page-section-content .content-inner {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}
section.static-page-section-content .content-inner .backlink {
  display: inline-block;
  margin-bottom: 30px;
}
section.static-page-section-content .content-inner .backlink .backlink-inner {
  display: flex;
  align-items: center;
}
section.static-page-section-content .content-inner .backlink .backlink-inner span {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
}
section.static-page-section-content .content-inner .backlink .backlink-inner img {
  margin-right: 15px;
  transform: rotate(180deg);
  height: 8px;
}
section.static-page-section-content .content-inner .heading {
  font-size: 32px;
  letter-spacing: 1px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .heading + .inner-gallery {
  margin-top: 35px;
}
section.static-page-section-content .content-inner .inner-info {
  margin-top: 35px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.static-page-section-content .content-inner .inner-info .info-service {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  margin-right: 30px;
}
section.static-page-section-content .content-inner .inner-short-description {
  margin-top: 35px;
  padding-top: 35px;
  border-top: 0.5px solid #ccc;
}
section.static-page-section-content .content-inner .inner-short-description p {
  color: #000;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
section.static-page-section-content .content-inner .inner-main-image {
  margin-top: 35px;
  height: 400px;
  overflow: hidden;
}
section.static-page-section-content .content-inner .inner-main-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
section.static-page-section-content .content-inner .inner-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service {
  margin-top: calc(4% / 3);
  display: block;
  width: 24%;
  padding-top: 20%;
  position: relative;
  overflow: hidden;
  background: #f9f9f9;
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service.service-hidden {
  margin-top: 0;
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
}
section.static-page-section-content .content-inner .inner-gallery.gallery-collapsed {
  margin-top: 0;
  padding-top: 0;
}
section.static-page-section-content .content-inner .btn-gallery-more {
  margin-top: 30px;
}
section.static-page-section-content .content-inner .btn-gallery-more.hidden {
  display: none;
}
section.static-page-section-content .content-inner .inner-description {
  margin-top: 35px;
}
section.static-page-section-content .content-inner .inner-description p,
section.static-page-section-content .content-inner .inner-description li {
  color: #000;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
section.static-page-section-content .content-inner .inner-description ol,
section.static-page-section-content .content-inner .inner-description ul {
  padding-left: 17px;
}
section.static-page-section-content .content-inner .inner-description h1 {
  font-size: 32px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-description h2 {
  font-size: 28px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-description h3 {
  font-size: 24px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-description h4 {
  font-size: 21px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-description h5 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-description h6 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
section.static-page-section-content .content-inner .inner-download {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #e5e5e5;
}
section.static-page-section-content .content-inner .inner-download .download-heading {
  text-transform: uppercase;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 25px;
}
section.static-page-section-content .content-inner .inner-download ul li a {
  display: flex;
  align-items: center;
}
section.static-page-section-content .content-inner .inner-download ul li a svg {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  fill: #c31432;
  margin-right: 10px;
}
section.static-page-section-content .content-inner .inner-download ul li a span {
  font-size: 14px;
  color: #000;
  text-decoration: underline;
  transition: all 0.4s;
}
section.static-page-section-content .content-inner .inner-download ul li a:hover span {
  color: #c31432;
}
section.static-page-section-content .content-inner .inner-download ul li + li {
  margin-top: 5px;
}
section.faq-section-content {
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;
}
section.faq-section-content .content-inner {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}
section.faq-section-content .content-inner .heading {
  text-align: center;
}
section.faq-section-content .content-inner .heading h1 {
  font-size: 50px;
  font-weight: 400;
  color: #000;
}
section.faq-section-content .content-inner .heading p {
  max-width: 600px;
  margin: 10px auto 0 auto;
  opacity: 0.7;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.3px;
}
section.faq-section-content .content-inner .accordion {
  margin-top: 60px;
}
section.faq-section-content .content-inner .accordion .accordion-item .accordion-header button {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  box-shadow: none;
}
section.faq-section-content .content-inner .accordion .accordion-item .accordion-body p,
section.faq-section-content .content-inner .accordion .accordion-item .accordion-body li {
  color: #000;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.3px;
}
section.faq-section-content .content-inner .accordion .accordion-item .accordion-body ol,
section.faq-section-content .content-inner .accordion .accordion-item .accordion-body ul {
  padding-left: 17px;
}
section.kontakt-section-content {
  transition: all 0.4s;
  padding: 100px 0;
  background: #fff;
}
section.kontakt-section-content .content-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
section.kontakt-section-content .content-inner .inner-contacts {
  width: 40%;
}
section.kontakt-section-content .content-inner .inner-contacts h1 {
  font-size: 48px;
  font-weight: 400;
  color: #000;
}
section.kontakt-section-content .content-inner .inner-contacts p {
  font-size: 34px;
  opacity: 0.5;
  padding-left: 65px;
}
section.kontakt-section-content .content-inner .inner-contacts address {
  margin-top: 50px;
  padding-left: 65px;
  margin-bottom: 0;
}
section.kontakt-section-content .content-inner .inner-contacts address .address-service {
  display: inline-block;
}
section.kontakt-section-content .content-inner .inner-contacts address .address-service .service-inner {
  display: flex;
  align-items: center;
}
section.kontakt-section-content .content-inner .inner-contacts address .address-service .service-inner svg {
  height: 25px;
  width: 25px;
  margin-right: 12px;
}
section.kontakt-section-content .content-inner .inner-contacts address .address-service .service-inner span {
  font-size: 25px;
  font-weight: 700;
}
section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media {
  margin-top: 30px;
  margin-left: 65px;
  display: flex;
  align-items: center;
}
section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media li.heading {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  opacity: 0.7;
}
section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media li a svg {
  height: 18px;
  width: 18px;
  fill: #000;
  transition: all 0.4s;
}
section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media li:not(:last-child) {
  margin-right: 10px;
}
section.kontakt-section-content .content-inner .inner-company-data {
  width: 40%;
  background: #f9f9f9;
  border-radius: 5px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
section.kontakt-section-content .content-inner .inner-company-data address {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 300;
  color: #000;
}
section.kontakt-section-content .content-inner .inner-company-data address span {
  font-weight: 600;
  font-size: 21px;
}
section.kontakt-section-content .content-hours {
  margin-top: 70px;
  padding: 0 5% 0 calc(5% + 65px);
}
section.kontakt-section-content .content-hours .heading {
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}
section.kontakt-section-content .content-hours .hours-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.kontakt-section-content .content-hours .hours-inner .inner-service {
  margin: 10px;
  padding: 15px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 2px;
}
section.kontakt-section-content .content-hours .hours-inner .inner-service .name {
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #000;
}
section.kontakt-section-content .content-hours .hours-inner .inner-service .value {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
section.kontakt-section-content .content-map-form-wrapper {
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .content-map-form-wrapper .content-form {
  width: 35%;
}
section.kontakt-section-content .content-map-form-wrapper .content-map {
  width: 60%;
  padding: 0;
}
section.kontakt-section-content .content-map-form-wrapper .content-map .map-inner {
  height: 100%;
  padding-top: 0;
}
section.kontakt-section-content .content-form {
  margin-top: 80px;
}
section.kontakt-section-content .content-form .heading {
  font-size: 34px;
  font-weight: 400;
  color: #000;
  margin-bottom: 30px;
}
section.kontakt-section-content .content-form .info {
  margin-top: 30px;
}
section.kontakt-section-content .content-form .info p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  text-transform: none;
  padding-left: 15px;
  color: #000;
}
section.kontakt-section-content .content-form .row {
  margin-top: -15px;
}
section.kontakt-section-content .content-form .checkbox-wrapper {
  margin-top: 30px;
}
section.kontakt-section-content .content-form .checkbox-wrapper + .checkbox-wrapper {
  margin-top: 10px;
}
section.kontakt-section-content .content-form .select-wrapper {
  border-width: 1px;
  border-color: #000;
}
section.kontakt-section-content .content-form .select-wrapper select {
  padding: 12px 100px 11px 19px;
  font-size: 14px;
  text-transform: none;
  color: #000;
  letter-spacing: 0;
}
section.kontakt-section-content .content-form .buttons-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kontakt-section-content .content-form .buttons-wrapper.center {
  justify-content: center;
}
section.kontakt-section-content .content-map {
  margin-top: 80px;
  padding: 0 5% 0 calc(5% + 65px);
}
section.kontakt-section-content .content-map .map-inner {
  width: 100%;
  padding-top: 50%;
  position: relative;
}
section.kontakt-section-content .content-map .map-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
section.kontakt-section-content .content-others {
  margin-top: 70px;
  padding: 0 5% 0 calc(5% + 65px);
}
section.kontakt-section-content .content-others .heading {
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  color: #000;
  margin-bottom: 50px;
}
section.kontakt-section-content .content-others .others-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.kontakt-section-content .content-others .others-inner .inner-service {
  margin: 1.5%;
  padding: 30px 20px 20px 20px;
  border-radius: 2px;
  border: 0.5px solid #ccc;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
}
section.kontakt-section-content .content-others .others-inner .inner-service * + * {
  margin-top: 10px;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-top {
  height: 25px;
  width: 25px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-iframe {
  position: relative;
  width: 100%;
  padding-top: 50%;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-iframe + .service-name {
  margin-top: 15px;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-image {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-image img {
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-name {
  color: #000;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-job {
  color: #000;
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0.7;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-address {
  color: #000;
  font-size: 14px;
  font-weight: 300;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-name + .service-job {
  margin-top: 3px;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-hours {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-hours .hour-box {
  margin: 3px;
  padding: 5px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 2px;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-hours .hour-box .name {
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  color: #000;
}
section.kontakt-section-content .content-others .others-inner .inner-service .service-hours .hour-box .value {
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
section.kontakt-section-content .content-others .others-inner .inner-service address {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 0.5px solid #ddd;
  margin-bottom: 0;
  position: relative;
}
section.kontakt-section-content .content-others .others-inner .inner-service address:after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  right: -20px;
  width: 40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
section.kontakt-section-content .content-others .others-inner .inner-service address a {
  margin-top: 0 !important;
  display: block;
}
section.section-footer {
  margin-top: 100px;
  transition: all 0.4s;
}
section.section-footer .footer-inner {
  display: flex;
}
section.section-footer .footer-inner .inner-service ul .heading {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
}
section.section-footer .footer-inner .inner-service ul li a {
  font-size: 14px;
  color: #000;
  white-space: nowrap;
  transition: all 0.4s;
}
section.section-footer .footer-inner .inner-service ul li + li {
  margin-top: 5px;
}
section.section-footer .footer-inner .inner-service .social-media {
  display: flex;
  align-items: center;
}
section.section-footer .footer-inner .inner-service .social-media li a svg {
  height: 18px;
  width: 18px;
  fill: #000;
  transition: all 0.4s;
}
section.section-footer .footer-inner .inner-service .social-media li + li {
  margin-top: 0;
}
section.section-footer .footer-inner .inner-service .social-media li:not(:last-child) {
  margin-right: 10px;
}
section.section-footer .footer-inner .inner-service address {
  margin-bottom: 0;
}
section.section-footer .footer-inner .inner-service address a {
  display: block;
  font-size: 16px;
  color: #000;
  transition: all 0.5s;
}
section.section-footer .footer-inner .inner-service address p {
  margin-top: 15px;
  font-size: 11px;
  color: #000;
}
section.section-footer .footer-inner .inner-service + .inner-service {
  margin-left: 125px;
}
section.section-footer .footer-credits {
  margin-top: 60px;
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
}
section.section-footer .footer-credits .credits-copyright {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text {
  font-size: 23px;
  font-weight: 600;
  color: #000;
  letter-spacing: -0.9px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text span {
  font-weight: 700;
  color: #c31432;
  font-size: 30px;
  line-height: 10px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image {
  min-width: 200px;
  max-width: 200px;
  position: relative;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image img {
  width: 100%;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-3 {
  min-width: 125px;
  max-width: 125px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-6 {
  min-width: 150px;
  max-width: 150px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-9 {
  min-width: 175px;
  max-width: 175px;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-12 {
  min-width: 200px;
  max-width: 200px;
}
section.section-footer .footer-credits .credits-copyright .divider {
  border-right: 1px solid #ccc;
  width: 1px;
  height: 30px;
  margin: 0 20px;
}
section.section-footer .footer-credits .credits-copyright p {
  font-size: 14px;
  color: #000;
}
section.section-footer .footer-credits .credits-copyright p a {
  color: inherit;
  transition: all 0.5s;
}
section.section-footer .footer-credits .credits-payment ul {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits .credits-payment ul li img {
  height: 15px;
}
section.section-footer .footer-credits .credits-payment ul li + li {
  margin-left: 15px;
}
section.section-footer.active {
  transform: translateX(-620px);
  filter: blur(5px);
}
.cookies-alert {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 888;
  width: 330px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 20px 30px;
  transition: all 0.4s;
}
.cookies-alert .btn-close {
  top: 4px;
  right: 0;
  outline: none;
  box-shadow: none;
  height: auto;
  width: auto;
  transition: opacity 0.4s;
  border: none;
  position: absolute;
  background: none;
  padding: 10px;
  cursor: pointer;
}
.cookies-alert .btn-close span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 12px;
  height: 1px;
  background: #fff;
}
.cookies-alert .btn-close span:nth-child(1) {
  transform: rotate(45deg) translateY(-50%);
}
.cookies-alert .btn-close span:nth-child(2) {
  transform: rotate(-45deg) translateY(-50%);
}
.cookies-alert .btn-close:after {
  content: none;
}
.cookies-alert p {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}
.cookies-alert p a {
  color: inherit;
  text-decoration: underline;
}
.cookies-alert.hidden {
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 1399.98px) {
  nav.section-header .header-management .management-search-engine input {
    min-width: 340px;
  }
  section.section-footer .footer-inner .inner-service + .inner-service {
    margin-left: 70px;
  }
}
@media (max-width: 1199.98px) {
  nav.section-header .header-management .management-logo .logo-image {
    min-width: 150px;
    max-width: 150px;
  }
  nav.section-header .header-management .management-logo .logo-image.image-size-9 {
    min-width: 150px;
    max-width: 150px;
  }
  nav.section-header .header-management .management-logo .logo-image.image-size-12 {
    min-width: 150px;
    max-width: 150px;
  }
  section.produkt-section-content .content-inner .product-info .info-add {
    flex-wrap: wrap;
  }
  section.produkt-section-content .content-inner .product-info .info-add .btn-fill {
    margin-top: 30px;
    margin-left: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-add .btn-empty {
    margin-top: 30px;
  }
  section.produkt-section-content .content-inner .product-info .info-add .breakpoint {
    width: 100%;
  }
  section.kontakt-section-content .content-inner {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  section.kontakt-section-content .content-inner .inner-contacts {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-inner .inner-contacts p {
    padding-left: 0;
  }
  section.kontakt-section-content .content-inner .inner-contacts address {
    padding-left: 0;
  }
  section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media {
    justify-content: center;
    margin-left: 0;
  }
  section.kontakt-section-content .content-inner .inner-company-data {
    margin-top: 40px;
    width: auto;
    padding: 50px 80px;
  }
  section.kontakt-section-content .content-hours {
    padding: 0;
    margin-top: 60px;
  }
  section.kontakt-section-content .content-form {
    margin-top: 60px;
  }
  section.kontakt-section-content .content-map {
    margin-top: 60px;
    padding: 0;
  }
  section.kontakt-section-content .content-others {
    margin-top: 60px;
    padding: 0;
  }
  section.section-footer .footer-inner {
    flex-wrap: wrap;
  }
  section.section-footer .footer-inner .inner-service:nth-child(1) {
    order: 1;
  }
  section.section-footer .footer-inner .inner-service:nth-child(2) {
    order: 2;
  }
  section.section-footer .footer-inner .inner-service:nth-child(3) {
    order: 3;
  }
  section.section-footer .footer-inner .inner-service:nth-child(4) {
    order: 4;
  }
  section.section-footer .footer-inner .inner-service:nth-child(5) {
    order: 6;
    margin-left: 0;
    margin-top: 40px;
  }
  section.section-footer .footer-inner .breakpoint {
    order: 5;
    width: 100%;
  }
  section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image {
    min-width: 150px;
    max-width: 150px;
  }
  section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-9 {
    min-width: 150px;
    max-width: 150px;
  }
  section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image.image-size-12 {
    min-width: 150px;
    max-width: 150px;
  }
}
@media (max-width: 991.98px) {
  .products-flexbox .product-service {
    width: 31%;
  }
  .products-flexbox .product-service .badge-wrapper .badge {
    width: 80px;
    height: 20px;
    font-size: 9px;
  }
  .products-flexbox.full-width .product-service {
    width: 23.5%;
  }
  .products-flexbox.grid-list .product-service header {
    min-width: 27%;
  }
  .category-nav-wrapper {
    position: relative;
  }
  .category-nav-wrapper .category-nav li a {
    font-size: 15px;
    letter-spacing: 0px;
  }
  .category-nav-wrapper .category-nav li:not(:last-child) {
    padding-bottom: 15px;
  }
  .category-nav-wrapper .category-nav li ul {
    margin-top: 12px;
    padding-left: 17px;
  }
  .category-nav-wrapper .category-nav li ul li {
    padding-bottom: 0 !important;
  }
  .category-nav-wrapper .category-nav li ul li a {
    font-size: 13px;
    letter-spacing: 0px;
  }
  .category-nav-wrapper .category-nav li ul li ul {
    margin-top: 10px;
  }
  .category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box {
    font-size: 15px;
    letter-spacing: 0px;
  }
  .category-nav-wrapper .filter-nav .checkbox-wrapper .radio-box .box-square {
    margin-top: 4px;
  }
  .category-nav-wrapper .filter-nav .range-wrapper input[type=number] {
    margin: 0 3px;
    font-size: 13px;
  }
  .category-nav-wrapper .filter-nav .range-wrapper p {
    font-size: 13px;
  }
  .category-nav-wrapper .filter-nav .buttons-wrapper .btn-clear {
    font-size: 11px;
  }
  .category-nav-wrapper .filter-nav .buttons-wrapper .btn-filter {
    font-size: 11px;
    padding: 3px 7px;
  }
  nav.section-header .header-top p {
    display: none;
  }
  nav.section-header .header-top .top-service {
    justify-content: space-between;
    width: 100%;
  }
  nav.section-header .header-top .top-social-media:after {
    content: none;
  }
  nav.section-header .header-management {
    flex-wrap: wrap;
    padding: 20px 0;
  }
  nav.section-header .header-management .management-logo {
    order: 1;
  }
  nav.section-header .header-management .management-offer {
    order: 4;
  }
  nav.section-header .header-management .management-breakpoint {
    height: 20px;
    order: 3;
    width: 100%;
    display: block;
  }
  nav.section-header .header-management .management-search-engine {
    order: 5;
  }
  nav.section-header .header-management .management-service {
    order: 2;
  }
  section.section-welcome .welcome-item {
    display: none;
  }
  section.section-welcome .owl-carousel-welcome {
    display: none;
  }
  section.section-welcome .welcome-mobile-item {
    display: block;
  }
  section.section-welcome .owl-carousel-welcome-mobile {
    display: block;
  }
  section.section-categories .categories-flexbox .flexbox-service .service-inner .inner-name {
    padding: 0;
  }
  section.section-categories .categories-flexbox .flexbox-service .service-inner .inner-name .name-box {
    padding: 10px;
    font-size: 14px;
    transition: all 0.4s;
  }
  section.section-categories .categories-flexbox .flexbox-service .service-inner:hover .inner-name {
    bottom: 0;
  }
  section.section-categories .categories-flexbox .flexbox-service .service-inner:hover .inner-name .name-box {
    padding: 20px 10px;
  }
  section.produkt-section-content .content-inner .product-info .info-producer > *:not(:first-child) {
    margin-top: 7px;
  }
  section.produkt-section-content .content-inner .product-info .info-producer .producer-inner {
    display: block;
  }
  section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-producer .producer-inner p:nth-child(2) {
    margin-left: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-features {
    margin-top: 30px;
  }
  section.produkt-section-content .content-inner .product-info .info-features > *:not(:first-child) {
    margin-top: 7px;
  }
  section.produkt-section-content .content-inner .product-info .info-features .feature-inner {
    display: block;
  }
  section.produkt-section-content .content-inner .product-info .info-features .feature-inner p {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-features .feature-inner p:nth-child(2) {
    margin-left: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-features .select-wrapper-flexbox {
    display: block;
  }
  section.produkt-section-content .content-inner .product-info .info-features .select-wrapper-flexbox label {
    font-size: 12px;
    margin-right: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-price {
    margin-top: 30px;
  }
  section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-inner p {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-price .price-availability .availability-comment p {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-add .service-quantity .input-number {
    width: 50px;
  }
  section.produkt-section-content .content-inner .product-info .info-add .btn-empty {
    margin-left: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-codes {
    margin-top: 30px;
    padding-top: 30px;
    display: block;
  }
  section.produkt-section-content .content-inner .product-info .info-codes .code-inner {
    display: block;
    margin-top: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-codes .code-inner p {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-codes .code-inner p:nth-child(2) {
    margin-left: 0;
  }
  section.produkt-section-content .content-inner .product-info .info-codes > *:not(:first-child) {
    margin-top: 7px;
  }
  section.produkt-section-content .content-inner .product-info .info-description {
    margin-top: 30px;
    padding-top: 30px;
  }
  section.produkt-section-content .content-inner .product-info .info-description .description-heading {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-description p,
  section.produkt-section-content .content-inner .product-info .info-description li {
    font-size: 12px;
  }
  section.produkt-section-content .content-inner .product-info .info-download {
    padding-top: 30px;
    margin-top: 30px;
  }
  section.produkt-section-content .content-inner .product-info .info-download .download-heading {
    font-size: 12px;
    margin-bottom: 20px;
  }
  section.produkt-section-content .content-inner .product-info .info-download ul li a span {
    font-size: 13px;
  }
  section.section-footer .footer-inner .inner-service:nth-child(1) {
    order: 1;
  }
  section.section-footer .footer-inner .inner-service:nth-child(2) {
    margin-left: 0;
    order: 4;
  }
  section.section-footer .footer-inner .inner-service:nth-child(3) {
    order: 5;
  }
  section.section-footer .footer-inner .inner-service:nth-child(4) {
    order: 6;
  }
  section.section-footer .footer-inner .inner-service:nth-child(5) {
    order: 2;
    margin-left: 70px;
    margin-top: 0;
  }
  section.section-footer .footer-inner .breakpoint {
    order: 3;
    height: 40px;
  }
  section.section-footer .footer-credits .credits-payment {
    display: none;
  }
}
@media (max-width: 785.98px) {
  .modal-service-wrapper .modal-service .service-inner .service-button {
    top: 15px;
    right: 15px;
    padding: 20px 15px 23px 15px;
  }
}
@media (max-width: 767.98px) {
  .body-wrapper.active {
    transform: translateX(-100%);
  }
  .breadcrumb-wrapper.subpage-hidden {
    display: none;
  }
  .products-flexbox .product-service {
    width: 48.25%;
  }
  .products-flexbox.full-width .product-service {
    width: 48.25%;
  }
  .categories-flexbox .category-service {
    width: 48.25%;
  }
  .news-flexbox .news-service {
    width: 48.25%;
  }
  .header-bag-sidebar {
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service {
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service .service-heading {
    padding: 20px;
    min-height: 76px;
  }
  .header-bag-sidebar .sidebar-service .service-inner {
    padding: 20px;
    width: 100%;
    max-height: calc(100vh - 205px);
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper {
    margin-top: 20px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper {
    min-width: 80px;
    max-width: 80px;
    padding-top: 128px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-image-wrapper.square {
    padding-top: 80px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name .info {
    margin-top: 5px;
  }
  .header-bag-sidebar .sidebar-service .service-summary {
    bottom: 61px;
    padding: 10px 21px 10px 20px;
  }
  .header-bag-sidebar .sidebar-service .service-footer {
    padding: 0 20px 20px 20px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner {
    padding: 20px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .heading {
    font-size: 18px;
    line-height: 22px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close {
    height: 22px;
    min-width: 22px;
    max-width: 22px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-heading .btn-modal-close span {
    width: 22px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .service-image-wrapper-outer {
    margin-top: 30px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features {
    margin-top: 30px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features .feature-inner p {
    font-size: 11px;
    letter-spacing: 0;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-features .feature-inner p:nth-child(2) {
    margin-left: 10px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-description {
    margin-top: 25px;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer {
    padding-top: 30px;
  }
  .header-bag-sidebar.favorite .sidebar-service .service-inner {
    max-height: calc(100vh - 157px);
  }
  .header-bag-sidebar.favorite .sidebar-service .service-footer {
    padding-top: 20px;
  }
  nav.section-header .header-top {
    display: none;
  }
  nav.section-header .header-management .management-offer {
    display: none;
  }
  nav.section-header .header-management .management-search-engine {
    width: 100%;
  }
  nav.section-header .header-management .management-search-engine input {
    width: 100%;
    min-width: auto;
  }
  nav.section-header .header-management .management-service .service-icons a svg,
  nav.section-header .header-management .management-service .service-icons button svg {
    height: 21px;
    width: 21px;
  }
  nav.section-header .header-management .management-service .service-icons a .cost-line,
  nav.section-header .header-management .management-service .service-icons button .cost-line {
    display: none;
  }
  nav.section-header .header-management .management-service .service-icons + .service-icons {
    margin-left: 30px;
  }
  nav.section-header .header-management .management-service .service-nav-button {
    margin-left: 20px;
    display: block;
  }
  section.section-welcome + section.section-categories {
    margin-top: 40px;
  }
  section.section-categories .categories-flexbox {
    flex-wrap: wrap;
  }
  section.section-categories .categories-flexbox .flexbox-service {
    margin-top: 20px;
  }
  section.section-categories .categories-flexbox .flexbox-service.service-wrapper .service-inner {
    padding-top: 70% !important;
  }
  section.section-categories .categories-flexbox .flexbox-service.service-wrapper .service-inner + .service-inner {
    margin-top: 20px;
  }
  section.section-categories .categories-flexbox.version-1 .flexbox-service {
    width: 100%;
    padding-top: 70%;
  }
  section.section-categories .categories-flexbox.version-2 .flexbox-service {
    width: 100%;
    padding-top: 70%;
  }
  section.section-categories .categories-flexbox.version-3 .flexbox-service {
    width: 100%;
    padding-top: 70%;
  }
  section.section-categories .categories-flexbox.version-3 .flexbox-service.service-double {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
  }
  section.section-categories .categories-flexbox.version-3 .flexbox-service.service-double .service-inner {
    width: 100%;
    padding-top: 70%;
  }
  section.section-advantages {
    margin-top: 40px;
  }
  section.section-advantages .advantages-flexbox {
    flex-wrap: wrap;
    justify-content: center;
  }
  section.section-advantages .advantages-flexbox .flexbox-service {
    margin-top: 30px;
    width: 33.33333333%;
  }
  section.section-offer {
    margin-top: 70px;
  }
  section.section-offer .section-heading h2 {
    font-size: 28px;
  }
  section.section-industries {
    margin-top: 70px;
  }
  section.section-industries .section-heading h2 {
    font-size: 28px;
  }
  section.section-industries .industries-flexbox .flexbox-service {
    margin: 5px;
    padding: 5px 13px;
    font-size: 14px;
  }
  section.section-cta {
    margin-top: 70px;
  }
  section.section-cta .cta-item .item-description {
    padding: 50px 30px;
  }
  section.section-cta .cta-item .item-description .text {
    font-size: 30px;
    line-height: 35px;
  }
  section.section-blog {
    margin-top: 70px;
  }
  section.section-blog.subpage {
    padding: 70px 0;
  }
  section.section-blog.subpage .news-flexbox .news-service:nth-child(3) {
    display: flex;
  }
  section.section-blog .section-heading {
    text-align: center;
  }
  section.section-blog .section-heading h1,
  section.section-blog .section-heading h2 {
    font-size: 28px;
  }
  section.section-blog .news-flexbox {
    margin-top: 15px;
  }
  section.section-blog .news-flexbox .news-service:nth-child(3) {
    display: none;
  }
  section.section-blog .btn-more-products-wrapper {
    margin-top: 50px;
  }
  section.section-newsletter {
    margin-top: 70px;
    padding: 70px 0;
  }
  section.section-newsletter .section-heading h2 {
    font-size: 28px;
  }
  section.kategoria-section-content {
    padding-bottom: 70px;
    padding-top: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .heading {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-categories-button {
    display: flex;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid-wrapper {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper {
    margin-top: 50px;
  }
  section.kategoria-section-content .content-description {
    padding-top: 50px;
    margin-top: 60px;
  }
  section.kategoria-section-content .content-description .description-inner h1,
  section.kategoria-section-content .content-description .description-inner h2 {
    font-size: 28px;
  }
  section.produkt-section-content {
    padding-bottom: 70px;
    padding-top: 0;
  }
  section.produkt-section-content .heading {
    display: block;
  }
  section.produkt-section-content .content-inner {
    flex-direction: column;
  }
  section.produkt-section-content .content-inner .product-gallery {
    width: 100%;
  }
  section.produkt-section-content .content-inner .product-info {
    margin-top: 40px;
    width: 100%;
  }
  section.produkt-section-content .content-inner .product-info .info-add .btn-fill {
    margin-right: 30px;
  }
  section.static-page-section-content {
    padding: 70px 0;
  }
  section.static-page-section-content.no-padding-mobile {
    padding: 0 0 70px 0;
  }
  section.static-page-section-content .content-inner .backlink {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
  }
  section.static-page-section-content .content-inner .backlink .backlink-inner {
    padding: 12px 0;
    border-bottom: 0.5px solid #ccc;
  }
  section.static-page-section-content .content-inner .backlink .backlink-inner span {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    color: #666;
  }
  section.static-page-section-content .content-inner .backlink .backlink-inner img {
    margin-right: 10px;
    transform: rotate(180deg);
    height: 10px;
  }
  section.static-page-section-content .content-inner .heading {
    font-size: 28px;
    letter-spacing: 0.5px;
  }
  section.faq-section-content {
    padding: 70px 0;
  }
  section.faq-section-content .content-inner .heading h1 {
    font-size: 32px;
  }
  section.faq-section-content .content-inner .accordion {
    margin-top: 40px;
  }
  section.faq-section-content .content-inner .accordion .accordion-item .accordion-header button {
    font-size: 15px;
    font-weight: 500;
  }
  section.kontakt-section-content {
    padding: 70px 0;
  }
  section.kontakt-section-content .content-inner .inner-contacts h1 {
    font-size: 28px;
  }
  section.kontakt-section-content .content-inner .inner-contacts p {
    font-size: 22px;
  }
  section.kontakt-section-content .content-inner .inner-contacts address .address-service .service-inner svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
  section.kontakt-section-content .content-inner .inner-contacts address .address-service .service-inner span {
    font-size: 18px;
    letter-spacing: -0.3px;
  }
  section.kontakt-section-content .content-inner .inner-contacts .contacts-social-media li.heading {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #000;
    opacity: 0.7;
  }
  section.kontakt-section-content .content-inner .inner-company-data {
    padding: 40px;
  }
  section.kontakt-section-content .content-hours .heading {
    font-size: 28px;
  }
  section.kontakt-section-content .content-hours .hours-inner .inner-service {
    margin: 3px;
    padding: 5px;
  }
  section.kontakt-section-content .content-map-form-wrapper {
    flex-direction: column;
  }
  section.kontakt-section-content .content-map-form-wrapper .content-form {
    width: 100%;
  }
  section.kontakt-section-content .content-map-form-wrapper .content-map {
    width: 100%;
  }
  section.kontakt-section-content .content-map-form-wrapper .content-map .map-inner {
    height: auto;
    padding-top: 50%;
  }
  section.kontakt-section-content .content-form .heading {
    font-size: 28px;
    text-align: center;
  }
  section.kontakt-section-content .content-form .buttons-wrapper > * {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-others .heading {
    font-size: 28px;
  }
  section.kontakt-section-content .content-others .others-inner .inner-service {
    margin: 1%;
    padding: 20px 10px;
    width: 48%;
  }
  section.section-footer {
    margin-top: 70px;
  }
  section.section-footer.active {
    transform: translateX(-100%);
  }
  section.section-footer .footer-credits {
    margin-top: 50px;
  }
  section.section-footer .footer-credits .credits-copyright {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
  section.section-footer .footer-credits .credits-copyright .divider {
    display: none;
  }
  section.section-footer .footer-credits .credits-copyright p {
    margin-top: 15px;
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  body .btn-border {
    padding: 10px 18px;
    font-size: 10.5px;
    letter-spacing: 0.4px;
  }
  body .btn-empty {
    font-size: 10.5px;
    letter-spacing: 0.4px;
  }
  body .btn-fill {
    padding: 10px 18px;
    font-size: 10.5px;
    letter-spacing: 0.4px;
  }
  body .btn-fill.btn-small {
    padding: 7px 14px;
  }
  .toast-container {
    right: 0;
    bottom: 0;
    padding: 0;
    width: 100%;
  }
  .toast-container .toast {
    margin-left: 15px;
    margin-bottom: 15px;
    width: calc(100% - 30px);
  }
  .select-wrapper select {
    font-size: 10.5px;
    letter-spacing: 0.4px;
    padding-right: 30px;
  }
  .header-bag-sidebar .sidebar-service .service-inner {
    max-height: calc(100vh - 225px);
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .product-service {
    flex-wrap: wrap;
    width: calc(100% - 25px);
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-name {
    width: calc(100% - 80px);
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity {
    margin-left: 0;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number {
    width: 30px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number,
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-decrement,
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-increment {
    height: 25px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-decrement,
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .service-quantity .input-number-increment {
    min-width: 25px;
  }
  .header-bag-sidebar .sidebar-service .service-inner .product-service-wrapper .breakpoint {
    height: 10px;
    display: block;
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .inner-footer {
    flex-direction: column;
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .inner-footer > * {
    width: 100%;
    text-align: center;
  }
  .header-bag-sidebar .sidebar-service .service-menu .category-nav-wrapper .inner-footer > * + * {
    margin-top: 10px;
    margin-left: 0;
  }
  .header-bag-sidebar .sidebar-service .service-summary {
    bottom: 105px;
    font-size: 14px;
    text-align: center;
  }
  .header-bag-sidebar .sidebar-service .service-summary br {
    content: '';
  }
  .header-bag-sidebar .sidebar-service .service-summary br:after {
    content: ' ';
  }
  .header-bag-sidebar .sidebar-service .service-footer {
    flex-direction: column-reverse;
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service .service-footer > * {
    width: 100%;
    text-align: center;
  }
  .header-bag-sidebar .sidebar-service .service-footer > * + * {
    margin-bottom: 10px;
    margin-left: 0;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer {
    flex-direction: column-reverse;
    width: 100%;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer > * {
    width: 100%;
    text-align: center;
  }
  .header-bag-sidebar .sidebar-service .product-modal .modal-inner .inner-footer > * + * {
    margin-bottom: 10px;
    margin-left: 0;
  }
  nav.section-header .header-management .management-logo .logo-text {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  nav.section-header .header-management .management-logo .logo-text span {
    font-size: 25px;
  }
  section.section-advantages .advantages-flexbox .flexbox-service {
    width: 50%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-categories-button span {
    font-size: 10.5px;
    letter-spacing: 0.4px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper {
    margin-top: 50px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .btn-more-products-wrapper .btn-more-products {
    padding: 10px 18px;
    font-size: 10.5px;
    letter-spacing: 0.4px;
  }
  section.static-page-section-content .content-inner .backlink {
    margin-bottom: 20px;
  }
  section.static-page-section-content .content-inner .inner-info {
    margin-top: 25px;
  }
  section.static-page-section-content .content-inner .inner-info .info-service {
    font-size: 10.5px;
    letter-spacing: 0.4px;
    margin-right: 20px;
  }
  section.static-page-section-content .content-inner .inner-short-description {
    margin-top: 25px;
    padding-top: 25px;
  }
  section.static-page-section-content .content-inner .inner-main-image {
    margin-top: 25px;
  }
  section.static-page-section-content .content-inner .inner-description {
    margin-top: 25px;
  }
  section.static-page-section-content .content-inner .inner-description h1 {
    font-size: 28px;
  }
  section.static-page-section-content .content-inner .inner-description h2 {
    font-size: 24px;
  }
  section.static-page-section-content .content-inner .inner-description h3 {
    font-size: 20px;
  }
  section.kontakt-section-content .content-others .others-inner .inner-service {
    margin: 1%;
    width: 100%;
  }
  section.section-footer .footer-inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  section.section-footer .footer-inner .inner-service .social-media {
    justify-content: center;
  }
  section.section-footer .footer-inner .inner-service + .inner-service {
    margin-left: 0;
    margin-top: 42px;
  }
  section.section-footer .footer-inner .breakpoint {
    display: none;
  }
  section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text {
    font-size: 20px;
    letter-spacing: -0.7px;
  }
  section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-text span {
    font-size: 25px;
  }
  .cookies-alert {
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    padding: 20px;
  }
}
